import { useState } from "react"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import {
  PutRequest,
  usePutProcedureMutation,
} from "@/services/backend/procedures/procedure/service"
import { Textarea } from "@/shared/components/ui/textarea"

interface ProcedureCommentComponentProps {
  procedure: Procedure
}

export function ProcedureCommentComponent({
  procedure,
}: ProcedureCommentComponentProps) {
  const [request, setRequest] = useState<PutRequest>({
    id: procedure.id,
    sampleSetId: procedure.sampleSetId,
    processId: procedure.processId,
    comment: "",
  })
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutProcedureMutation()

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  return (
    <div className="flex flex-row">
      <Textarea
        className="h-10 min-h-10"
        placeholder="Kommentar"
        defaultValue={procedure.comment}
        onChange={e => {
          if (e.target.value)
            setRequest({ ...request, comment: e.target.value })
        }}
        disabled={isLoading}
      />
    </div>
  )
}
