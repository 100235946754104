import ConfirmationDialog from "@/shared/components/domain/confirmation/confirmation-dialog"
import { Button } from "@/shared/components/ui/button"
import { useParams } from "react-router-dom"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import ProcedureTable from "@/routes/processes/[processId]/process/analytics/_components/procedure-table"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"
import { format } from "date-fns"
import {
  AddMeasurementRequest,
  useAddMeasurementsMutation,
} from "@/services/backend/procedures/procedure/service"
import { useCallback, useState } from "react"
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { ArtefactNotReadyAlert } from "@/shared/service-manager/artefact/artefact-sheet"
import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page"
import StartIncubationButton from "./start-incubation-button"

const getTimestamp = (timestamp: string): string => {
  const formattedDate = format(timestamp, "dd.MM.yyyy")
  const formattedTime = format(timestamp, "HH:mm:ss")
  return `${formattedDate} - ${formattedTime}`
}

export function AnalyticsIndexRoute() {
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )
  const { notReadyError } = useErrArtefactNotReady()
  const [saveMeasurements] = useAddMeasurementsMutation()
  const [measurements, setMeasurements] = useState<AddMeasurementRequest[]>([])
  const addMeasurement = useCallback((measurement: AddMeasurementRequest) => {
    setMeasurements(prevItems => {
      // Check if the item with the same ID already exists
      const existingIndex = prevItems.findIndex(
        (item: AddMeasurementRequest) =>
          item.incubationId === measurement.incubationId,
      )

      if (existingIndex !== -1) {
        // Replace the existing item
        return [
          ...prevItems.slice(0, existingIndex),
          measurement,
          ...prevItems.slice(existingIndex + 1),
        ]
      }
      // Add the new item
      return [...prevItems, measurement]
    })
  }, [])

  const save = () => {
    saveMeasurements({ measurements, processId: processId as string })
    setMeasurements([])
  }

  return (
    <MilestonePage>
      <MilestonePageHeader>
        {process != null && processId != null && (
          <div className="flex w-full flex-row items-center justify-between">
            {process.status === "Created" || process.status === "Scheduled" ? (
              <StartIncubationButton processId={processId} />
            ) : (
              <div className="flex flex-row space-x-2">
                <h4 className="font-bold">Inkubationsstart:</h4>
                <p>{getTimestamp(process.incubationStart)}</p>
              </div>
            )}
            <div className="flex flex-row space-x-2">
              {process.status !== "Created" &&
                process.status !== "Scheduled" && (
                  <ConfirmationDialog
                    trigger={
                      <Button className="bg-black text-white" variant="outline">
                        Speichern
                      </Button>
                    }
                    title="Ergebnisse speichern"
                    description="Hiermit werden die eingetragenen Ergebnisse gespeichert. Bereits eingetragene Ergebnisse können aufgrund der Revisionssicherheit nicht mehr gelöscht werden."
                    onConfirm={() => save()}
                  />
                )}
            </div>
          </div>
        )}
      </MilestonePageHeader>
      <MilestonePageContent>
        {process != null && process.status !== "IncubationStarted" && (
          <ArtefactNotReadyAlert error={notReadyError} artefact="Probensets" />
        )}
        <ProcedureTable
          label="1N-RLT"
          processId={processId as string}
          procedureType={ProcedureType.RLT1N}
          addMeasurement={addMeasurement}
        />

        <ProcedureTable
          label="2N-RLT"
          processId={processId as string}
          procedureType={ProcedureType.RLT2N}
          addMeasurement={addMeasurement}
        />
      </MilestonePageContent>
    </MilestonePage>
  )
}
