import { Outlet } from "react-router-dom"
import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page"
import { Separator } from "@/shared/components/ui/separator"
import { AirVent, CircleX, PackageOpen, Pipette } from "lucide-react"
import { BreadcrumbsToBasedata } from "@/shared/service-manager/default-ui/base-data-page/breadcrumbs-to-basedata"

export default function BaseDataRoute() {
  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
      </ProcessPageSidebar>
      <ProcessPageMain>
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToBasedata />
          </div>
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  )
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: "Stammdaten",
    items: [
      {
        label: "Probenfehler",
        icon: CircleX,
        to: "sample-errors",
      },
      {
        label: "Schimmelpilze",
        icon: Pipette,
        to: "moulds",
      },
      {
        label: "Chargen",
        icon: PackageOpen,
        to: "batches",
      },
      {
        label: "Luftkeimsammler",
        icon: AirVent,
        to: "airsamplers",
      },
    ],
  },
]
