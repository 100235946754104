import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { usePutDeliveryMutation } from "@/services/backend/deliveries/delivery/service"
import { Condition } from "@/services/backend/deliveries/state/state"
import { packageConditions } from "@/shared/components/enum-labels"

const FormSchema = z.object({
  condition: z.string(),
})

interface UpdateConditionFormProps {
  deliveryId: string
  currentCondition?: string
}

export function UpdateConditionForm({
  deliveryId,
  currentCondition,
}: UpdateConditionFormProps) {
  const [updateCondition, { reset }] = usePutDeliveryMutation()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    updateCondition({
      id: deliveryId,
      packageCondition: data.condition as Condition,
    })
    reset()
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-42" variant="outline">
          Paketzustand aktualisieren
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Paketzustand aktualisieren</DialogTitle>
          <DialogDescription>
            Hier können Sie den Zustand des Pakets bearbeiten.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              <FormField
                control={form.control}
                name="condition"
                defaultValue={currentCondition || ""}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Paketzustand</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          defaultValue={currentCondition || "fine"}
                        >
                          <SelectValue placeholder="Zustand auswählen" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent defaultValue={currentCondition || "fine"}>
                        {Object.values(Condition).map(value => (
                          <SelectItem key={value} value={value}>
                            {packageConditions[value]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      Aussage über den Zustand des Pakets
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Speichern</Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
