import { Input } from "@/shared/components/ui/input"

interface CommentInputComponentProps {
  comment: string
  disabled?: boolean
  submitHandler: (comment: string) => void
}

export function CommentInputComponent({
  comment,
  submitHandler,
  disabled = false,
}: CommentInputComponentProps) {
  return (
    <div className="flex flex-row">
      <Input
        type="text"
        placeholder="Kommentar"
        defaultValue={comment}
        onChange={e => {
          if (e.target.value) submitHandler(e.currentTarget.value)
        }}
        disabled={disabled}
      />
    </div>
  )
}
