import { Navigate, RouteObject } from "react-router-dom"
import { ReportRoute } from "@/routes/processes/[processId]/process/inspections/[reportId]/inspection-route"
import { AnalyticsIndexRoute } from "@/routes/processes/[processId]/process/inspections/[reportId]/analytics/analytics-index-route"
import { ResultIndexRoute } from "@/routes/processes/[processId]/process/inspections/[reportId]/results/result-index-route"
import { ReportsIndexRoute } from "./_components/reports-index-route"
import { DocumentationIndexRoute } from "./[reportId]/samples/documentation-index-route"
import { SetOverviewIndexRoute } from "./[reportId]/sample-sets/set-overview-index-route"
import { ContactsIndexRoute } from "./[reportId]/contacts/contacts_index_route"
import { CustomerIndexRoute } from "./[reportId]/customers/customers_index_route"
import { BatchesIndexRoute } from "./[reportId]/batches/batches-index-route"

export const inspectionsRoutes: RouteObject[] = [
  {
    index: true,
    element: <ReportsIndexRoute />,
  },
  {
    path: ":reportId",
    element: <ReportRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="results" />,
      },
      {
        path: "results",
        element: <ResultIndexRoute />,
      },
      {
        path: "analytics",
        element: <AnalyticsIndexRoute />,
      },
      {
        path: "sample-sets",
        element: <SetOverviewIndexRoute />,
      },
      {
        path: "samples",
        element: <DocumentationIndexRoute />,
      },
      {
        path: "batches",
        element: <BatchesIndexRoute />
      },
      {
        path: "contacts",
        element: <ContactsIndexRoute />,
      },
      {
        path: "customers",
        element: <CustomerIndexRoute />
      }

    ],
  },
]
