import { ReactElement, useState } from "react"
import {
  ProcedureType,
  SampleSet,
} from "@/services/backend/samples/sample-set/sample-set"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import {
  PutRequest,
  usePutSampleSetMutation,
} from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { useParams } from "react-router-dom"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { hasFieldError } from "@/shared/app-lib/errors/validation-error"

interface ProcedureSelectorProps {
  sampleSet: SampleSet
}

function ProcedureSelector({
  sampleSet,
}: ProcedureSelectorProps): ReactElement {
  const { notReadyError } = useErrArtefactNotReady()
  const { processId } = useParams()
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )

  const hasProcedureTypeError = hasFieldError(
    notReadyError,
    `sampleSets.${sampleSet.id}.procedureType`,
  )

  const [request, setRequest] = useState<PutRequest>({
    id: sampleSet.id,
    customerId: sampleSet.customerId,
    deliveryId: sampleSet.deliveryId,
    procedureType: sampleSet.procedureType,
    airSamplerId: sampleSet.airSamplerId,
  })

  const [upsertSet, { error, isLoading, isSuccess, reset }] =
    usePutSampleSetMutation()
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    upsertSet,
    isLoading,
    error,
    isSuccess,
    reset,
    300,
    { toastError: true },
  )

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div
      className={
        hasProcedureTypeError ? "rounded-lg border border-red-500 p-2" : ""
      }
    >
      <Select
        disabled={
          process != null
            ? process.completed || process.status === "IncubationStarted"
            : false
        }
        value={request.procedureType}
        onValueChange={(value: ProcedureType) =>
          setRequest({ ...sampleSet, procedureType: value })
        }
      >
        <SelectTrigger className="w-24">
          <SelectValue placeholder="Bitte Verfahren wählen" />
        </SelectTrigger>
        <SelectContent>
          {Object.values(ProcedureType).map(value => (
            <SelectItem key={value} value={value}>
              {value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default ProcedureSelector
