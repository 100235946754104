import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover"
import { useState } from "react"
import { Button } from "@/shared/components/ui/button"
import { Check, ListFilter } from "lucide-react"
import t from "@/lang/lang"
import { Filter } from "@/shared/lib/filter/filter"
import { ProcessStatus } from "@/services/backend/processes/process/process"
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command"
import { cn } from "@/shared/lib/utils"
import { StatusLabel } from "@/shared/components/domain/processes/get-status-label"

export interface StatusFilterProps {
  filter: Filter<ProcessStatus>
  onChange: (filter: Filter<ProcessStatus>) => void
}

export function StatusFilter({ filter, onChange }: StatusFilterProps) {
  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{t("Zustand")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandList>
            <CommandGroup>
              {Object.values(ProcessStatus).map(label => (
                <CommandItem
                  key={label}
                  value={label}
                  onSelect={value => {
                    const current = value as ProcessStatus
                    const values = filter.values!.includes(current)
                      ? filter.values!.filter(v => v !== current)
                      : [...filter.values!, current]
                    if (values.length === 0) {
                      onChange({ active: false, values: [] })
                    } else {
                      onChange({ active: true, values })
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values!.includes(label)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  <StatusLabel status={label} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
