import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
  CardDescription,
} from "@/shared/components/ui/card"
import { Progress } from "@/shared/components/ui/progress"
import { Condition, State } from "@/services/backend/deliveries/state/state"
import type { ReactNode } from "react"
import { CalendarDays, PackageCheck, Truck } from "lucide-react"
import { cn } from "@/shared/lib/utils"
import { UpdateConditionForm } from "@/routes/deliveries/[deliveryId]/delivery/overview/_components/update-condition-form"
import { useGetDeliveryQuery } from "@/services/backend/deliveries/delivery/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { CustomerName } from "@/shared/components/domain/customer/customer-name"
import { formatDate } from "@/shared/utilities/date"
import { de } from "date-fns/locale"
import { UpdateDeliveryStateForm } from "./update-delivery-state"

interface DeliveryStateProps {
  deliveryId: string
}

export default function DeliveryState({ deliveryId }: DeliveryStateProps) {
  const icons: { [key: string]: ReactNode } = {
    [State.Announced]: <CalendarDays className="h-4 w-4" />,
    [State.InDelivery]: <Truck className="h-4 w-4" />,
    [State.Delivered]: <PackageCheck className="h-4 w-4" />,
  }

  const progress: { [key: string]: number } = {
    [State.Announced]: 33.3,
    [State.InDelivery]: 66.6,
    [State.Delivered]: 100,
  }

  const condition: { [key: string]: ReactNode } = {
    [Condition.Fine]: "In Ordnung",
    [Condition.Damaged]: "Lieferung beschädigt",
    [Condition.Rejected]: "Zurückgewiesen",
  }

  const { data: delivery, error } = useGetDeliveryQuery({
    id: deliveryId as string,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Card className="w-full">
      <CardHeader className="flex items-start">
        <CardTitle className="text-3xl">Paketstatus</CardTitle>
        <CardDescription>
          Hier werden sämtliche Informationen über die Zustellung Ihres Paketes
          getrackt
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col items-center justify-center gap-4">
          {delivery != null && (
            <div className="w-full space-y-4">
              <div className="grid w-full grid-cols-3 gap-4">
                <div className="flex flex-col items-center">
                  <div
                    className={cn(
                      "flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white",
                      delivery?.deliveryState === State.Delivered ||
                        delivery?.deliveryState === State.Announced ||
                        delivery?.deliveryState === State.InDelivery
                        ? "bg-green-600"
                        : "bg-primary",
                    )}
                  >
                    {icons[State.Announced]}
                  </div>
                  <div className="mt-2 text-sm font-medium">Angekündigt</div>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={cn(
                      "flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white",
                      delivery?.deliveryState === State.InDelivery ||
                        delivery?.deliveryState === State.Delivered
                        ? "bg-green-600"
                        : "bg-primary",
                    )}
                  >
                    {icons[State.InDelivery]}
                  </div>
                  <div className="mt-2 text-sm font-medium">In Zustellung</div>
                </div>
                <div className="flex flex-col items-center">
                  <div
                    className={cn(
                      "flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white",
                      delivery?.deliveryState === State.Delivered
                        ? "bg-green-600"
                        : "bg-primary",
                    )}
                  >
                    {icons[State.Delivered]}
                  </div>
                  <div className="mt-2 text-sm font-medium">Angekommen</div>
                </div>
              </div>
              <Progress
                value={progress[delivery.deliveryState]}
                className={cn("w-full")}
              />
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex flex-col items-start space-y-4">
        {delivery != null && (
          <div>
            <div className="font-semibold">Kunde</div>
            <div>
              <CustomerName customerId={delivery.customerId} />
            </div>
          </div>
        )}
        {delivery != null && (
          <div>
            <div className="font-semibold">Paketzustand</div>
            <div>{condition[delivery.packageCondition]}</div>
          </div>
        )}
        {delivery != null && delivery.trackingId !== "" && (
          <div>
            <div className="font-semibold">Tracking Nummer</div>
            <div>{delivery?.trackingId}</div>
          </div>
        )}
        {delivery != null &&
          delivery.deliveryState === State.Delivered &&
          delivery.deliveryDate !== "" && (
            <div>
              <div className="font-semibold">Ankunftsdatum</div>
              <div>{formatDate(delivery.deliveryDate, de)}</div>
            </div>
          )}
        <div className="flex flex-row space-x-1">
          <UpdateConditionForm
            deliveryId={deliveryId}
            currentCondition={delivery?.packageCondition}
          />
          <UpdateDeliveryStateForm
            deliveryId={deliveryId}
            currentDeliveryState={delivery?.deliveryState}
          />
        </div>
      </CardFooter>
    </Card>
  )
}
