import type {
  ColumnDef,
  Table as ReactTable,
  VisibilityState,
} from "@tanstack/react-table"
import { ReactElement } from "react"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { SampleSetNumber } from "@/shared/components/domain/sample-set/sample-set-number"
import {
  Incubation,
  Procedure,
} from "@/services/backend/procedures/procedure/procedure"
import { sampleTypes } from "@/shared/components/enum-labels"
import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import { H4 } from "@/shared/components/typography"
import { useListProceduresQuery } from "@/services/backend/procedures/procedure/service"
import MouldResult from "@/shared/components/domain/moulds/mould-result"
import { CollapsibleDataTable } from "@/shared/components/collapsible-data-table"
import DifferentiationMetadataRow from "@/routes/processes/[processId]/process/differentiation/_components/differentiation-metadata-row"
import { Badge } from "@/shared/components/ui/badge"
import MeasurementLabelComponent from "./measurement-label-component"

function getMeasurementUnit(
  procedureType: ProcedureType,
  sequenceNumber: number,
): string {
  switch (procedureType) {
    case ProcedureType.RLT2N:
      if (sequenceNumber === 1) {
        return "KBE/Platte"
      }
      return "KBE/Platte"
    case ProcedureType.RLT1N:
      if (sequenceNumber === 1) {
        return "KBE/Platte"
      }
      return "KBE/Platte"
    default:
      return ""
  }
}

function getResultUnit(procedureType: ProcedureType): string {
  switch (procedureType) {
    case ProcedureType.RLT2N:
      return "KBE/m3"
    case ProcedureType.RLT1N:
      return `25/cm2`
    default:
      return ""
  }
}

const createTableColumns = (
  procedureType: ProcedureType,
): ColumnDef<Procedure>[] => [
  {
    id: "sampleSet",
    accessorKey: "procedure",
    header: "Probenset",
    cell: ({ row }) => {
      const procedureData = row.original
      return <SampleSetNumber sampleSetId={procedureData.sampleSetId} />
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleType",
    header: "Probentyp",
    cell: ({ row }) => {
      const procedureData = row.original
      return <div>{sampleTypes[procedureData.sampleType]}</div>
    },
  },
  {
    id: "measurement-1",
    accessorKey: "incubation",
    header: `1. Auszählung (${getMeasurementUnit(procedureType, 1)})`,
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 1,
      )
      return incubation != null ? (
        <MeasurementLabelComponent incubation={incubation} />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "corrected-measurement-1",
    accessorKey: "incubation",
    header: `1. Korrekturwert (${getMeasurementUnit(procedureType, 1)})`,
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 1,
      )
      return incubation != null ? (
        <MeasurementLabelComponent incubation={incubation} correctedValue />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "measurement-2",
    accessorKey: "incubation",
    header: `2. Auszählung (${getMeasurementUnit(procedureType, 2)})`,
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 2,
      )
      return incubation != null ? (
        <MeasurementLabelComponent incubation={incubation} />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "corrected-measurement-2",
    accessorKey: "incubation",
    header: `2. Korrekturwert (${getMeasurementUnit(procedureType, 2)})`,
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 2,
      )
      return incubation != null ? (
        <MeasurementLabelComponent incubation={incubation} correctedValue />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },

  {
    id: "results",
    accessorKey: "results",
    header: `Ergebnisse (${getResultUnit(procedureType)})`,
    cell: ({ row }) => {
      const procedureData = row.original
      if (procedureData.result.exceedsThreshold) {
        return <Badge>&gt;100</Badge>
      }

      return <Badge>{procedureData.result.value}</Badge>
    },
  },
  {
    id: "mouldResult",
    accessorKey: "id",
    header: "Schimmelpilze",
    cell: ({ row }) => {
      const procedureData = row.original
      return (
        <div className="flex items-center space-x-2">
          <MouldResult
            sampleSetId={procedureData.sampleSetId}
            procedure={procedureData}
          />
        </div>
      )
    },
  },
]

interface CalculationTableProps {
  processId: string
  reportId: string
  procedureType: ProcedureType
  label: string
  visibilityState?: VisibilityState
  collapsible?: boolean
}

function ResultTable({
  processId,
  reportId,
  procedureType,
  label,
  visibilityState = {},
  collapsible = false,
}: CalculationTableProps): ReactElement {
  const {
    data: list,
    error,
    isLoading,
    isFetching,
  } = useListProceduresQuery(
    {
      processId: processId as string,
      procedureType: procedureType as ProcedureType,
      ownerId: reportId as string,
      ownerType: "inspection.Inspection",
      limit: 0,
      page: 0,
    },
    { refetchOnMountOrArgChange: true },
  )

  const cols = createTableColumns(procedureType)
  const table = useDataTableController(cols, list?.data || [], visibilityState)

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={cols} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (list != null && list.data != null && list.data.length > 0) {
    return (
      <div className="flex w-full flex-col space-y-4">
        <H4>{label}</H4>
        <DataTableContainer className="w-full">
          <CollapsibleDataTable
            table={table}
            columns={cols}
            renderCollapsibleContent={procedure => (
              <DifferentiationMetadataRow procedure={procedure} />
            )}
            collapsibleTriggerId={collapsible ? "sampleSet" : ""}
          />
        </DataTableContainer>
      </div>
    )
  }

  return <div />
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}

export default ResultTable
