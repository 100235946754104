import { backendApi } from "@/services/api"
import {
  ArtefactImmutabilityReason,
  Inspection,
} from "@/services/backend/inspections/inspection"
import { previewResponseHandler } from "@/services/backend/pdf-helpers"
import { Contact } from "../contacts/contact/contact"
import { Customer } from "../customers/customer/customer"

const inspectionsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    listInspections: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/list`,
        method: "GET",
        params: {
          limit: request.limit,
          offset: request.offset,
          processId: request.processId,
        },
      }),
      providesTags: ["Inspections"],
    }),
    getInspection: build.query<Response, GetRequest>({
      query: request => ({
        url: `/inspections`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Inspections", id: request.id },
      ],
    }),
    putInspection: build.mutation<Response, PutRequest>({
      query: request => ({
        url: `/inspections`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Inspections"],
    }),
    postInspection: build.mutation<Response, PostRequest>({
      query: request => ({
        url: `/inspections`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Inspections"],
    }),
    inspectionPdfPreview: build.query<{ objectURL: string }, PreviewPDFRequest>(
      {
        query: request => ({
          url: `/inspections/pdf`,
          method: "PATCH",
          body: request,
          // headers: {
          //   Accept: "application/pdf",
          // },
          responseHandler: previewResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        providesTags: (_r, _e, request) => [
          { type: "Inspections", id: request.inspectionId },
        ],
      },
    ),
    inspectionUpdateContact: build.mutation<
      Inspection,
      UpdateInspectionContact
    >({
      query: request => ({
        url: `/inspections/contact`,
        method: "PATCH",
        body: {
          id: request.id,
          contact: request.contact,
        },
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "Inspections", id: request.id },
        "Inspections",
      ],
    }),
    inspectionUpdateCustomer: build.mutation<
      Inspection,
      UpdateInspectionCustomer
    >({
      query: request => ({
        url: `/inspections/customer`,
        method: "PATCH",
        body: {
          id: request.id,
          customer: request.customer,
        },
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "Inspections", id: request.id },
        "Inspections",
      ],
    }),
    setInspectionImmutable: build.mutation<Inspection, SetImmutableRequest>({
      query: request => ({
        url: `/inspections/immutable`,
        method: "PATCH",
        body: {
          id: request.id,
          reason: request.reason,
          setBy: request.setBy,
        },
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "Inspections", id: request.id },
        "Inspections",
      ],
    }),
    getLatestInspectionByProcess: build.query<
      Response,
      GetLatestInspectionByProcessRequest
    >({
      query: request => ({
        url: `/inspections/latest-by-process`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Inspections", id: request.processId },
      ],
    }),
  }),
})

export const {
  usePutInspectionMutation,
  usePostInspectionMutation,
  useGetInspectionQuery,
  useListInspectionsQuery,
  useInspectionUpdateContactMutation,
  useInspectionUpdateCustomerMutation,
  useInspectionPdfPreviewQuery,
  useGetLatestInspectionByProcessQuery,
  useSetInspectionImmutableMutation,
} = inspectionsApi

export interface PutRequest {
  id: string
  processId: string
}

export interface PostRequest {
  id: string
  processId: string
  customerId: string
}

export interface Response {
  inspection: Inspection
}

export interface GetRequest {
  id: string
}

export interface ListRequest {
  processId: string
  limit: number
  offset: number
}

export interface List {
  count: number
  items: Inspection[]
}

export interface PreviewPDFRequest {
  inspectionId: string
}

export interface UpdateInspectionContact {
  id: string
  contact: Contact
}

export interface UpdateInspectionCustomer {
  id: string
  customer: Customer
}

export interface GetLatestInspectionByProcessRequest {
  processId: string
}

export interface SetImmutableRequest {
  id: string
  reason: ArtefactImmutabilityReason
  setBy: string
}
