import { Outlet, useParams } from "react-router-dom"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page"
import { Separator } from "@/shared/components/ui/separator"
import { Package, Phone } from "lucide-react"
import { useGetDeliveryQuery } from "@/services/backend/deliveries/delivery/service"
import { BreadcrumbsToDelivery } from "@/shared/service-manager/default-ui/delivery-page/breadcrumbs-to-delivery"

export default function DeliveryRoute() {
  const { deliveryId } = useParams()
  const {
    data: delivery,
    isLoading,
    error,
  } = useGetDeliveryQuery({
    id: deliveryId ?? "",
  })

  if (!delivery || isLoading) {
    return null
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
      </ProcessPageSidebar>
      <ProcessPageMain>
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToDelivery delivery={delivery} />
          </div>
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  )
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: "Allgemein",
    items: [
      {
        label: "Ansprechpartner",
        icon: Phone,
        to: "contact",
      },
    ],
  },
  {
    label: "Daten",
    items: [
      {
        label: "Übersicht",
        icon: Package,
        to: "overview",
      },
    ],
  },
]
