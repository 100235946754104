import type { ColumnDef } from "@tanstack/react-table"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { Batch } from "@/services/backend/batches/batch/batch"
import { formatDate } from "@/shared/utilities/date"
import { de } from "date-fns/locale"
import { growthMedia, sampleTypes } from "@/shared/components/enum-labels"

const tableColumns: ColumnDef<Batch>[] = [
  {
    id: "batchNumber",
    accessorKey: "batchNumber",
    header: "Chargennummer",
    cell: ({ row }) => {
      const batchData = row.original
      return <div>{batchData.batchNumber}</div>
    },
  },
  {
    id: "growthMedium",
    accessorKey: "growthMedium",
    header: "Nährmedium",
    cell: ({ row }) => {
      const batchData = row.original
      return <div>{growthMedia[batchData.growthMedium]}</div>
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleType",
    header: "Probentyp",
    cell: ({ row }) => {
      const batchData = row.original
      return <div>{sampleTypes[batchData.sampleType]}</div>
    },
  },
  {
    id: "expiryDate",
    accessorKey: "expiryDate",
    header: "Ablaufdatum",
    cell: ({ row }) => {
      const batchData = row.original
      return <div>{formatDate(batchData.expiryDate, de)}</div>
    },
  },
]

interface BatchListTableProps {
  batches: Batch[]
}

export function BatchListTable({ batches = [] }: BatchListTableProps) {
  const table = useDataTableController(tableColumns, batches)

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}


