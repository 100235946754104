import { v4 } from "uuid"

export type UUID = string

export const NullUUID = "00000000-0000-0000-0000-000000000000"

/**
 * Entity ID type, handling the behavior of entity ids from the nidavellir
 * package.
 */
export type EntityId = UUID | null

export function createEntityId(): EntityId {
  return v4()
}
