import { useParams } from "react-router"
import {
  useGetInspectionQuery,
} from "@/services/backend/inspections/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { BatchListTable } from "./batch-table"

export function BatchesIndexRoute() {
  const { reportId } = useParams()
  const { data, isLoading, error } = useGetInspectionQuery({
    id: reportId as string,
  })

  if (isLoading || !data) {
    return <div />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <BatchListTable batches={data.inspection.batches} />
  )
}
