import { NavLink, useLocation } from "react-router-dom"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shared/components/ui/breadcrumb"
import { CustomerName } from "@/shared/components/domain/customer/customer-name"
import { Customer } from "@/services/backend/customers/customer/customer"

export const subPageLabel: { [key: string]: string } = {
  contact: "Ansprechpartner",
  "api-key": "API-Keys",
}

export function BreadcrumbsToCustomers({ customer }: { customer: Customer }) {
  const location = useLocation()

  // extract the path prefix for everything before the process id
  const idx = location.pathname.indexOf(customer.id)

  // extract the artefact kind path segment, if it exists
  const fullDeliveryPath = location.pathname.slice(idx + customer.id.length + 1)
  const subPage = fullDeliveryPath.split("/")[0]

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to={`/customers/${customer.id}/contact`}>
              <CustomerName customerId={customer.id} />
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="hidden md:block" />
        {subPage && (
          <BreadcrumbItem>
            <BreadcrumbPage>{subPageLabel[subPage]}</BreadcrumbPage>
          </BreadcrumbItem>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
