import { NavLink, useLocation } from "react-router-dom"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shared/components/ui/breadcrumb"
import { CustomerName } from "@/shared/components/domain/customer/customer-name"
import { ProcessNumber } from "@/shared/components/domain/processes/process-number"
import { Process } from "@/services/backend/processes/process/process"
import { artefactLabel } from "@/shared/components/enum-labels"

/**
 * BreadcrumbsToArtefact is a component attempting to build breadcrumbs
 * for a process page leading to an artefact. The breadcrumbs are derived
 * from the current location, but not based on it.
 * BreadcrumbsToArtefact will attempt to build a list of breadcrumbs
 * in this format: {customerName}-{processNumber}-{artefactKind}-{artefactNumber}.
 * BreadcrumbsToArtefact will further rely on multiple conventions:
 * - artefact id route parameters are named e.g. "offerId" for an offer artefact
 * - a customer page exists at /customers/{customerId}
 * - process routes are designed as {prefix}/{processId}/{artefactKind (plural)}/{artefactId}
 * - the translation function can translate textual route segments
 * BreadcrumbsToArtefact is a sensible default, but may be customized as needed.
 */
export function BreadcrumbsToArtefact({ process }: { process: Process }) {
  const location = useLocation()

  // extract the path prefix for everything before the process id
  const idx = location.pathname.indexOf(process.id)
  const prefix = location.pathname.slice(0, idx)

  // extract the artefact kind path segment, if it exists
  const fullArtefactPath = location.pathname.slice(idx + process.id.length + 1)
  const artefactKind = fullArtefactPath.split("/")[0]

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to={`/customers/${process.customerId}/contact`}>
              <CustomerName customerId={process.customerId} />
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="hidden md:block" />
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to={`${prefix}${process.id}`}>
              <ProcessNumber
                className="font-mono font-bold"
                processId={process.id}
              />
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {artefactKind && (
          <>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>{artefactLabel[artefactKind]}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
