import { FormItem } from "@/shared/components/ui/form"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { Input } from "@/shared/components/ui/input"
import { ScrollableDifferentiationList } from "@/routes/processes/[processId]/process/differentiation/_components/scrollable-differentiation-list"
import { useState } from "react"
import { useDebounce } from "@/shared/lib/debounce/debounce"
import { useListMouldsQuery } from "@/services/backend/mould/service"
import { TestTube } from "lucide-react"
import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { PersistenceStateUsingContext } from "@/shared/persistence-state/persistence-state-info"
import { useListDifferentiationsQuery } from "@/services/backend/differentiation/service"

function useSearchDebounce(
  value: string,
  delay?: number,
  minLength: number = 1,
): string {
  const [search, setSearch] = useState(value)

  if (value.length >= minLength && value !== search) {
    setSearch(value)
  }

  return useDebounce(search, delay)
}

interface DifferentiationDialogProps {
  sampleSetId: string
  disabled?: boolean
}

export function PutDifferentiationDialog({
  sampleSetId,
  disabled = false,
}: DifferentiationDialogProps) {
  const [search, setSearch] = useState("")
  const query = useSearchDebounce(search, 400, 0)
  const { data: moulds } = useListMouldsQuery({ searchString: query })
  const { data: sampleSet } = useGetSampleSetQuery({ id: sampleSetId })
  const { data: differentiations } = useListDifferentiationsQuery({
    sampleSetId,
    limit: 0,
    offset: 0,
  })

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled} variant="outline">
          <TestTube />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Differenzierung</DialogTitle>
          <DialogDescription>
            Bitte geben Sie die Werte zu den festgestellten Schimmelpilzen ein.
          </DialogDescription>
        </DialogHeader>

        {moulds != null &&
          moulds.data != null &&
          differentiations &&
          differentiations.items &&
          sampleSet != null && (
            <div className="flex w-full items-center space-y-2">
              <FormItem className="w-full">
                <Input
                  placeholder="Suche..."
                  onChange={e => setSearch(e.currentTarget.value)}
                />
                <ScrollableDifferentiationList
                  moulds={moulds.data}
                  sampleSet={sampleSet}
                  differentiations={differentiations.items}
                />
              </FormItem>
            </div>
          )}
        <PersistenceStateUsingContext />
      </DialogContent>
    </Dialog>
  )
}
