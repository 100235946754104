import { useGetLatestInspectionByProcessQuery } from "@/services/backend/inspections/service"
import { ReactElement } from "react"
import { Skeleton } from "@/shared/components/ui/skeleton"
import { RTKQueryErrorAlert } from "../errors/rtk-query-error-alert"
import { is404 } from "../errors/parse-r-t-k-query-error"
import { Username } from "../username/username"

interface InspectionCreatedFromProps {
  processId: string
}

export function InspectionCreatedFrom({
  processId,
}: InspectionCreatedFromProps): ReactElement {
  const { data, isLoading, error } = useGetLatestInspectionByProcessQuery({
    processId: processId as string,
  })

  if (error) {
    if (is404(error)) {
      return <span>Kein Bericht erstellt</span>
    }
    return <RTKQueryErrorAlert error={error} />
  }

  if (isLoading) {
    return <Skeleton className="h-1 w-4" />
  }

  if (!data) {
    return <span className="font-mono">Kein Bericht erstellt</span>
  }

  return (
    <Username
      userId={data.inspection.immutabilitySetBy}
      className="items-start"
    />
  )
}
