import { Outlet, useParams } from "react-router-dom"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page"
import { Separator } from "@/shared/components/ui/separator"
import { KeyRound, Package, Phone } from "lucide-react"
import { BreadcrumbsToCustomers } from "@/shared/service-manager/default-ui/customers-page/breadcrumbs-to-customers"
import { useGetCustomerQuery } from "@/services/backend/customers/customer/service"

export default function CustomerRoute() {
  const { customerId } = useParams()
  const {
    data: customer,
    isLoading,
    error,
  } = useGetCustomerQuery({
    id: customerId ?? "",
  })

  if (!customer || isLoading) {
    return null
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
      </ProcessPageSidebar>
      <ProcessPageMain>
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToCustomers customer={customer} />
          </div>
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  )
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: "Allgemein",
    items: [
      {
        label: "Ansprechpartner",
        icon: Phone,
        to: "contact",
      },
      {
        label: "Lieferungsvorlagen",
        icon: Package,
        to: "delivery-templates",
      },
    ],
  },
  {
    label: "Einstellungen",
    items: [
      {
        label: "API-Keys",
        icon: KeyRound,
        to: "api-key",
      },
    ],
  },
]
