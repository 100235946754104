import { useState, useEffect, useMemo } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { Button } from "@/shared/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { Input } from "@/shared/components/ui/input"
import { ScrollArea } from "@/shared/components/ui/scroll-area"
import { Checkbox } from "@/shared/components/ui/checkbox"
import { SampleError } from "@/services/backend/samples/sample-error/sample-error"
import { useListSampleErrorsQuery } from "@/services/backend/samples/sample-error/service"
import { useListErrorAssignmentViewModelQuery } from "@/services/backend/samples/sample-set/sample-service"
import {
  ErrorAssignmentViewModel,
  EvaluableType,
  GrowthMedium,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import { LandPlot, Wind } from "lucide-react"
import { evaluableTypes, growthMedia } from "@/shared/components/enum-labels"
import { useEvaluateSampleMutation } from "@/services/backend/samples/sample-evaluation/service"
import { FacetedOptions, FacetedFilter } from "./faceted-filter"

// Options for FacetedFilter SampleType
const sampleTypeOptions: FacetedOptions[] = [
  {
    label: "Luftkeim",
    value: SampleType.AirMicrobial,
    icon: Wind,
  },
  {
    label: "Oberflächenkeim",
    value: SampleType.SurfaceMicrobial,
    icon: LandPlot,
  },
]

// Options for FacetedFilter GrowthMedium
const growthMediumOptions: FacetedOptions[] = [
  {
    label: growthMedia[GrowthMedium.Caso],
    value: GrowthMedium.Caso,
  },
  {
    label: growthMedia[GrowthMedium.Malt],
    value: GrowthMedium.Malt,
  },
  {
    label: growthMedia[GrowthMedium.DG18],
    value: GrowthMedium.DG18,
  },
]

interface ErrorAssignmentDialogProps {
  processId: string
}

export default function ErrorAssignmentDialog({
  processId,
}: ErrorAssignmentDialogProps) {
  const [evaluateSample] = useEvaluateSampleMutation()
  const [entries, setEntries] = useState<ErrorAssignmentViewModel[]>([])
  const { data: samples } = useListErrorAssignmentViewModelQuery({
    processId,
    limit: 0,
    offset: 0,
  })

  useEffect(() => {
    if (samples != null && samples.data && samples.data.length > 0) {
      setEntries(samples.data)
    }
  }, [samples, setEntries])

  const [selectedValue, setSelectedValue] = useState<string | null>(null)
  const [selectedEvaluableType, setSelectedEvaluableType] =
    useState<EvaluableType>(EvaluableType.Evaluable)
  const { data: sampleError } = useListSampleErrorsQuery({ limit: 0, page: 0 })
  useEffect(() => {
    if (
      sampleError != null &&
      sampleError.data &&
      sampleError.data.length > 0
    ) {
      setSelectedValue(sampleError.data[0].id)
    }
  }, [setSelectedValue, sampleError])

  useEffect(() => {
    if (
      sampleError != null &&
      sampleError.data &&
      sampleError.data.length > 0 &&
      samples != null &&
      samples.data &&
      samples.data.length > 0
    ) {
      const selectedEntries: ErrorAssignmentViewModel[] = []
      samples.data.forEach(sample => {
        if (sample.sampleErrors != null && sample.sampleErrors.length > 0) {
          sample.sampleErrors.forEach(assignedError => {
            if (assignedError.id === selectedValue) {
              selectedEntries.push(sample)
            }
          })
        }
      })
      setSelectedEntries(selectedEntries)
    }
  }, [sampleError, samples, selectedValue])

  const [selectedEntries, setSelectedEntries] = useState<
    ErrorAssignmentViewModel[]
  >([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedSampleTypes, setSelectedSampleTypes] = useState<string[]>([])
  const [selectedGrowthMedia, setSelectedGrowthMedia] = useState<string[]>([])
  const filteredEntries = useMemo(() => {
    let filtered = entries.filter(entry =>
      entry.sampleSetNumber.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    if (selectedGrowthMedia.length > 0) {
      filtered = filtered.filter(entry =>
        selectedGrowthMedia.includes(entry.growthMedium),
      )
    }

    if (selectedSampleTypes.length > 0) {
      filtered = filtered.filter(entry =>
        selectedSampleTypes.includes(entry.sampleType),
      )
    }

    return filtered
  }, [entries, searchTerm, selectedGrowthMedia, selectedSampleTypes])

  const handleValueAssignment = () => {
    if (selectedValue) {
      const checkedSamples = selectedEntries.map(entry => entry.id)
      const sampleErrorState = {
        id: selectedValue,
        isChecked: true,
      }

      evaluateSample({
        evaluableType: selectedEvaluableType,
        sampleIds: checkedSamples,
        sampleErrorState: [sampleErrorState],
      })

      setSelectedEntries([])
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Fehlerzuweisung</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Fehlerzuweisung</DialogTitle>
          <DialogDescription>
            Wählen Sie einen Fehler und anschließend die betroffenen Proben aus,
            um die Fehler zuzuweisen.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-2 py-4">
          <div className="w-full items-center space-y-2">
            <h2 className="font-bold">Auswertbarkeit</h2>
            <Select
              defaultValue={selectedEvaluableType || ""}
              onValueChange={(value: string) =>
                setSelectedEvaluableType(value as EvaluableType)
              }
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Wählen Sie einen Fehler aus" />
              </SelectTrigger>
              <SelectContent>
                {Object.values(EvaluableType).map(value => (
                  <SelectItem key={value} value={value}>
                    {evaluableTypes[value]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {sampleError != null &&
            sampleError.data != null &&
            sampleError.data.length > 0 && (
              <div className="w-full items-center space-y-2">
                <h2 className="font-bold">Probenfehler</h2>
                <Select
                  defaultValue={selectedValue || ""}
                  onValueChange={setSelectedValue}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Wählen Sie einen Fehler aus" />
                  </SelectTrigger>
                  <SelectContent>
                    {sampleError.data.map((value: SampleError) => (
                      <SelectItem key={value.id} value={value.id}>
                        {value.description}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          <div className="mt-4 space-y-2">
            <h2 className="font-bold">Proben</h2>
            <Input
              placeholder="Proben suchen..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="mb-2"
            />
            <FacetedFilter
              title="Probentyp"
              options={sampleTypeOptions}
              selectedValues={selectedSampleTypes}
              setSelectedValues={setSelectedSampleTypes}
            />
            <FacetedFilter
              title="Nährmedium"
              options={growthMediumOptions}
              selectedValues={selectedGrowthMedia}
              setSelectedValues={setSelectedGrowthMedia}
            />

            <ScrollArea className="h-[200px] w-full rounded-md border p-4">
              {filteredEntries.map(entry => (
                <div
                  key={entry.id}
                  className="mb-2 flex items-center space-x-2"
                >
                  <Checkbox
                    id={`entry-${entry.id}`}
                    checked={selectedEntries.some(
                      sample => sample.id === entry.id,
                    )}
                    onCheckedChange={checked => {
                      setSelectedEntries(prev =>
                        checked
                          ? [...prev, entry]
                          : prev.filter(sample => sample.id !== entry.id),
                      )
                    }}
                  />
                  <label htmlFor={`entry-${entry.id}`}>
                    {entry.sampleSetNumber} ({growthMedia[entry.growthMedium]})
                    - {evaluableTypes[entry.evaluableType]}
                  </label>
                </div>
              ))}
            </ScrollArea>

            <div className="mb-8 flex items-center justify-end space-x-2">
              <Checkbox
                id="select-all"
                checked={
                  selectedEntries.length === filteredEntries.length &&
                  filteredEntries.length > 0
                }
                onCheckedChange={checked =>
                  checked
                    ? setSelectedEntries(filteredEntries)
                    : setSelectedEntries([])
                }
                aria-labelledby="select-all-label" // Optional for better accessibility
              />
              <span>Alle auswählen</span>
            </div>
          </div>
          <Button
            onClick={handleValueAssignment}
            disabled={!selectedValue || selectedEntries.length === 0}
          >
            Fehler zuweisen
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
