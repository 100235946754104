import { DeliveryTemplate } from "@/services/backend/delivery-templates/delivery"
import { useListDeliveryTemplatesQuery } from "@/services/backend/delivery-templates/service"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import type { Table as ReactTable } from "@tanstack/react-table"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { ColumnDef } from "@tanstack/react-table"
import CreateDeliveryTemplateModal from "./create-delivery-template"

interface DeliveryTemplatesListTableProps {
  customerId: string
}

const tableColumns: ColumnDef<DeliveryTemplate>[] = [
  {
    id: "templateDescription",
    accessorKey: "templateDescription",
    header: "Beschreibung",
    cell: ({ row }) => row.original.templateDescription,
  },
  {
    id: "templateId",
    accessorKey: "templateId",
    header: "Details",
    cell: ({ row }) => (
      <CreateDeliveryTemplateModal templateId={row.original.templateId} />
    ),
  },
]

export function DeliveryTemplatesListTable({
  customerId,
}: DeliveryTemplatesListTableProps) {
  const {
    data: templateData,
    error,
    isLoading,
    isFetching,
  } = useListDeliveryTemplatesQuery({
    customerId: customerId as string,
    limit: 0,
    offset: 0,
  })
  const table = useDataTableController(tableColumns, templateData?.items || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <DataTableContainer>
      <DataTable table={table} columns={tableColumns} />
    </DataTableContainer>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}
