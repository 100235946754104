import { Batch } from "../batches/batch/batch"
import { Contact } from "../contacts/contact/contact"
import { Customer } from "../customers/customer/customer"

export enum ArtefactImmutabilityReason {
  InspectionReleased = "InspectionReleased",
}

export interface Inspection {
  id: string
  processId: string
  customerId: string
  immutabilitySetAt: string
  immutabilitySetBy: string
  immutabilityReason: ArtefactImmutabilityReason
  immutable: boolean
  createdAt: string
  updatedAt: string
  contact: Contact
  customer: Customer
  batches: Batch[]
}
