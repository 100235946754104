import { zodResolver } from "@hookform/resolvers/zod"
import { Control, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { useState } from "react"
import { Plus, Trash2 } from "lucide-react"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { Card, CardContent } from "@/shared/components/ui/card"
import { ScrollArea } from "@/shared/components/ui/scroll-area"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/shared/components/ui/accordion"
import {
  AnnounceDeliveryRequest,
  useAnnounceDeliveryMutation,
} from "@/services/backend/deliveries/delivery/service"
import { createEntityId } from "@/shared/nidavellir/types/entity-id"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  GrowthMedium,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import { Input } from "@/shared/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { growthMedia, sampleTypes } from "@/shared/components/enum-labels"
import { CustomerFormSelect } from "@/shared/components/domain/customer/customer-form-select"
import { SampleSetAnnouncement } from "@/services/backend/deliveries/delivery/delivery"
import { DeliveryTemplate } from "@/services/backend/delivery-templates/delivery"
import DeliveryTemplateSelector from "@/shared/components/domain/templates/delivery-template-selector"

// SampleAnnouncement Schema
const SampleAnnouncementSchema = z.object({
  id: z.string().default((): string => createEntityId()!.toString()),
  description: z.string().min(1, "Eine Beschreibung wird benötigt"),
  growthMedium: z.nativeEnum(GrowthMedium),
  createdAt: z.string().default((): string => {
    const now = new Date()
    return now.toISOString()
  }),
})

// SampleSetAnnouncement Schema
const SampleSetAnnouncementSchema = z.object({
  id: z.string().default((): string => createEntityId()!.toString()),
  sampleSetNumber: z.string().min(1, "Eine Probensetnummer wird benötigt"),
  sampleType: z.nativeEnum(SampleType),
  referenceId: z.string().min(1, "Eine Referenz wird benötigt"),
  comment: z.string().optional(),
  samples: z.array(SampleAnnouncementSchema).refine(
    arr => {
      // Extract 'type' values from the array of objects
      const types = arr.map(item => item.growthMedium)
      // Check if the 'types' array has any duplicates by comparing its size to a Set of types
      return new Set(types).size === types.length
    },
    {
      message: "Each 'type' must be unique in the array",
    },
  ),
})

// DeliveryAnnouncement Schema
const DeliveryAnnouncementSchema = z.object({
  id: z.string().default((): string => createEntityId()!.toString()),
  customerId: z.string(),
  trackingId: z.string().optional(),
  description: z.string().min(1, "Eine Beschreibung wird benötigt"),
  sampleSets: z
    .array(SampleSetAnnouncementSchema)
    .min(
      1,
      "Mindesten ein Probenset muss in einer Lieferung enthaltenseiMindesten ein Probenset muss in einer Lieferung enthaltensein",
    ),
})

type FormData = z.infer<typeof DeliveryAnnouncementSchema>

export default function CreateDeliveryModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [announceDelivery, { error }] = useAnnounceDeliveryMutation()
  const form = useForm<FormData>({
    resolver: zodResolver(DeliveryAnnouncementSchema),
    defaultValues: {
      id: createEntityId()?.toString(),
      description: "",
      trackingId: "",
      sampleSets: [],
    },
  })
  const { control, handleSubmit, watch, reset } = form
  const {
    fields: sampleSetFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "sampleSets",
  })

  const customerId = watch("customerId")

  const onSubmit = (data: FormData) => {
    const request: AnnounceDeliveryRequest = {
      id: data.id,
      customerId: data.customerId,
      description: data.description,
      trackingId: data.trackingId ? data.trackingId : undefined,
      sampleSets: data.sampleSets.map(
        (set): SampleSetAnnouncement => ({
          id: set.id,
          sampleSetNumber: set.sampleSetNumber,
          comment: set.comment ? set.comment : undefined,
          sampleType: set.sampleType,
          referenceId: set.referenceId,
          caso: set.samples.find(
            sample => sample.growthMedium === GrowthMedium.Caso,
          ),
          dg18: set.samples.find(
            sample => sample.growthMedium === GrowthMedium.DG18,
          ),
          malt: set.samples.find(
            sample => sample.growthMedium === GrowthMedium.Malt,
          ),
        }),
      ),
    }

    announceDelivery(request)
    setIsOpen(false)
    reset({
      id: createEntityId()?.toString(), // Generate new ID
      customerId: "", // Explicitly reset customerId
      description: "",
      trackingId: "",
      sampleSets: [], // Reset arrays
    })
  }

  const setTemplate = (template: DeliveryTemplate) => {
    const parsed = DeliveryAnnouncementSchema.partial().parse(template)
    const finalizedData = {
      ...parsed,
      id: createEntityId()!.toString(),
      sampleSets: parsed.sampleSets
        ? parsed.sampleSets.map(set => ({
            ...set,
            id: createEntityId()!.toString(),
            samples: set.samples.map(sample => ({
              ...sample,
              id: createEntityId()!.toString(),
            })),
          }))
        : [],
    }
    reset(finalizedData)
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Lieferung anlegen</Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col sm:max-w-[425px] md:max-w-[700px] lg:max-w-[900px]">
        <DialogHeader>
          <DialogTitle>Lieferung anlegen</DialogTitle>
          <DialogDescription>
            Hier können neue Lieferungen angemeldet werden, welche nicht durch
            ein angebundenes System automatisch erstellt werden.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-1 flex-col"
          >
            <div className="mb-4 space-y-4">
              <CustomerFormSelect name="customerId" control={form.control} />
              <DeliveryTemplateSelector
                customerId={customerId}
                setDeliveryTemplate={(template: DeliveryTemplate) =>
                  setTemplate(template)
                }
              />
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Beschreibung</FormLabel>
                      <FormControl>
                        <Input placeholder="Beschreibung" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="trackingId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tracking-Id</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Tracking-Id (optional)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Card container with ScrollArea */}
            <Card className="flex-1">
              <CardContent className="p-0">
                {/* Scroll Area should be the only scrollable area */}
                <ScrollArea className="h-[400px]">
                  <Accordion type="multiple" className="flex w-full flex-col">
                    {sampleSetFields.map((sampleSet, sampleSetIndex) => (
                      <AccordionItem
                        value={`sampleSet-${sampleSetIndex}`}
                        key={sampleSet.id}
                      >
                        <AccordionTrigger className="px-4">
                          <div className="flex w-full items-center justify-between">
                            <span>Probenset {sampleSetIndex + 1}</span>
                            <Button
                              type="button"
                              variant="ghost"
                              size="icon"
                              onClick={e => {
                                e.stopPropagation()
                                remove(sampleSetIndex)
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="space-y-4 px-4">
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.sampleSetNumber`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Probensetnummer</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Probensetnummer"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.referenceId`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Referenz</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Referenz"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.sampleType`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Probentyp</FormLabel>
                                    <FormControl>
                                      <Select
                                        onValueChange={field.onChange}
                                        defaultValue={field.value}
                                      >
                                        <FormControl>
                                          <SelectTrigger
                                            defaultValue={
                                              field.value ||
                                              SampleType.SurfaceMicrobial
                                            }
                                          >
                                            <SelectValue placeholder="Probentyp auswählen" />
                                          </SelectTrigger>
                                        </FormControl>
                                        <SelectContent
                                          defaultValue={
                                            field.value ||
                                            SampleType.SurfaceMicrobial
                                          }
                                        >
                                          {Object.values(SampleType).map(
                                            value => (
                                              <SelectItem
                                                key={value}
                                                value={value}
                                              >
                                                {sampleTypes[value]}
                                              </SelectItem>
                                            ),
                                          )}
                                        </SelectContent>
                                      </Select>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.comment`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Kommentar</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Kommentar"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <SampleFields
                              control={control}
                              sampleSetIndex={sampleSetIndex}
                            />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </ScrollArea>
              </CardContent>
            </Card>

            {/* Buttons */}
            <div className="flex justify-between space-x-4 pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() =>
                  append({
                    id: createEntityId()?.toString() as string,
                    sampleSetNumber: "",
                    sampleType: SampleType.SurfaceMicrobial,
                    comment: "",
                    referenceId: "",
                    samples: [],
                  })
                }
              >
                <Plus className="mr-2 h-4 w-4" /> Probenset hinzufügen
              </Button>
              <Button type="submit">Lieferung speichern</Button>
            </div>
          </form>
        </Form>
      </DialogContent>{" "}
    </Dialog>
  )
}

type SampleFieldsProps = {
  control: Control<FormData>
  sampleSetIndex: number
}

function SampleFields({ control, sampleSetIndex }: SampleFieldsProps) {
  const {
    fields: itemFields,
    append: addItem,
    remove,
  } = useFieldArray({
    control,
    name: `sampleSets.${sampleSetIndex}.samples`,
  })

  return (
    <div className="flex w-full flex-col space-y-2 rounded-lg border p-4">
      <h1 className="text-lg font-bold">Proben</h1>
      {itemFields.map((sample, sampleIndex) => (
        <div key={sample.id} className="grid w-full grid-cols-2 gap-4">
          <FormField
            control={control}
            name={`sampleSets.${sampleSetIndex}.samples.${sampleIndex}.description`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Beschreibung</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={`sampleSets.${sampleSetIndex}.samples.${sampleIndex}.growthMedium`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Nährmedium</FormLabel>
                <div className="flex w-full flex-row items-center space-x-2">
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue placeholder="Nährmedium" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.values(GrowthMedium).map(value => (
                          <SelectItem key={value} value={value}>
                            {growthMedia[value]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={e => {
                      e.stopPropagation()
                      remove(sampleIndex)
                    }}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
      <Button
        variant="outline"
        className="mt-8 w-40"
        type="button"
        onClick={() => {
          const now = new Date()
          addItem({
            id: createEntityId()!.toString(),
            description: "",
            growthMedium: GrowthMedium.Caso,
            createdAt: now.toISOString(),
          })
        }}
      >
        <Plus className="h-4 w-4" /> Probe hinzufügen
      </Button>
    </div>
  )
}
