import { ReactElement, useState } from "react"
import {
  Incubation,
  Measurement,
} from "@/services/backend/procedures/procedure/procedure"
import { Input } from "@/shared/components/ui/input"
import { v4 as uuidv4 } from "uuid"
import { useAuth } from "@/shared/lib/authorization/auth-context"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { skipToken } from "@reduxjs/toolkit/query"
import { AddMeasurementRequest } from "@/services/backend/procedures/procedure/service"
import { GrowthMedium } from "@/services/backend/samples/sample-set/sample-set"
import { Checkbox } from "@/shared/components/ui/checkbox"
import { useParams } from "react-router"
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { hasFieldError } from "@/shared/app-lib/errors/validation-error"
import { formatDate } from "@/shared/utilities/date"
import { cn } from "@/shared/lib/utils"

interface MeasurementInputProps {
  procedureId: string
  incubation: Incubation
  addMeasurement: (measurement: AddMeasurementRequest) => void
}

/* const checkIfMeasurementIsAllowed = (
  incubation: Incubation,
): boolean => {
  const now = Date.now()
  const startCountDate = new Date(incubation.countStartTimestamp)
  const endCountDate = new Date(incubation.countEndTimestamp)

  return !(startCountDate.getTime() > now || endCountDate.getTime() < now)
} */

const getGrowthMediaLabel = (incubation: Incubation): string => {
  if (incubation.sequenceNumber === 1) {
    if (incubation.growthMedium === GrowthMedium.Caso) {
      return "Bakterien"
    }
  }

  if (incubation.sequenceNumber === 2) {
    if (incubation.growthMedium === GrowthMedium.Caso) {
      return "Schimmelpilze"
    }
    return "Schimmelpilze / Hefen"
  }

  return ""
}

function MeasurementInput({
  incubation,
  procedureId,
  addMeasurement,
}: MeasurementInputProps): ReactElement {
  const { user } = useAuth()
  const { processId } = useParams()
  const { notReadyError } = useErrArtefactNotReady()
  const hasError = hasFieldError(
    notReadyError,
    `procedures.${procedureId}.${incubation.id}.measurements`,
  )
  const { data: process } = useGetProcessQuery(
    processId != null ? { id: processId } : skipToken,
  )
  const previousMeasurement = incubation.measurements.find(
    currentMeasurement => currentMeasurement.isCurrent,
  )

  const [measurement, setMeasurement] = useState<Measurement>(
    previousMeasurement
      ? { ...previousMeasurement, id: uuidv4() }
      : {
          id: uuidv4(),
          employeeId: user?.id as string,
          incubationId: incubation.id,
          isCurrent: false,
          exceedsThreshold: false,
          measurementDate: "",
          measurement: 0,
          correctedMeasurement: 0,
        },
  )

  const updateMeasurement = (updatedMeasurement: Measurement): void => {
    const newMeasurement = updatedMeasurement
    if (newMeasurement.exceedsThreshold) {
      newMeasurement.measurement = undefined
      newMeasurement.correctedMeasurement = undefined
    }

    setMeasurement(newMeasurement)
    addMeasurement({
      employeeId: measurement.employeeId,
      exceedsThreshold: newMeasurement.exceedsThreshold,
      incubationId: measurement.incubationId,
      measurementId: measurement.id,
      value: newMeasurement.measurement,
      measurementTime: new Date().toISOString(),
      procedureId,
    })
  }

  return (
    <div className="flex flex-row items-center space-x-2">
      <div className="flex flex-col space-y-1">
        <div
          className={cn(
            "flex flex-row items-center justify-start space-x-1",
            hasError ? "rounded-lg border border-red-500 p-2" : "",
          )}
        >
          <Input
            value={
              measurement.measurement != null ? measurement.measurement : ""
            }
            type="number"
            className="w-32"
            placeholder="Auszählung"
            min={0}
            max={100}
            onChange={e => {
              let value: number | undefined = Number(e.currentTarget.value)
              let exceedsThreshold = false
              if (value > 100) {
                value = undefined
                exceedsThreshold = true
              }
              updateMeasurement({
                ...measurement,
                measurement: value,
                exceedsThreshold,
              })
            }}
            disabled={
              (process != null ? process.completed : false) ||
              measurement.exceedsThreshold
            }
          />
          <Checkbox
            id="greater-than-100"
            checked={measurement.exceedsThreshold}
            disabled={process != null ? process.completed : false}
            onCheckedChange={checked =>
              updateMeasurement({
                ...measurement,
                exceedsThreshold: checked as boolean,
              })
            }
          />
          <p className="text-gray-700 text-muted-foreground">{">100"}</p>
        </div>

        <p className="text-gray-700 text-muted-foreground">
          {getGrowthMediaLabel(incubation)}
        </p>

        <div className="text-gray-700 text-muted-foreground">
          Auszählbar von {formatDate(incubation.countStartTimestamp)} bis{" "}
          {formatDate(incubation.countEndTimestamp)}
        </div>
      </div>
    </div>
  )
}

export default MeasurementInput
