import {
  Measurement,
  Procedure,
} from "@/services/backend/procedures/procedure/procedure"
import { Username } from "@/shared/components/domain/username/username"
import { growthMedia } from "@/shared/components/enum-labels"
import { Card } from "@/shared/components/ui/card"
import { formatDate, formatTime } from "@/shared/utilities/date"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table"
import { de } from "date-fns/locale"
import { ReactElement } from "react"

interface IncubationMetadataRowProps {
  procedure: Procedure
}

function IncubationMetadataRow({
  procedure,
}: IncubationMetadataRowProps): ReactElement {
  if (
    !procedure ||
    !procedure.incubation ||
    procedure.incubation.length === 0
  ) {
    return <div>Keine Inkubationen gefunden</div>
  }

  return (
    <div className="flex flex-col space-y-4">
      {procedure?.incubation.map(inc => (
        <Card key={inc.id} className="flex flex-col space-y-2 p-4">
          <div className="flex flex-row space-x-1">
            <p className="font-bold">Art: </p>
            <p>{growthMedia[inc.growthMedium]}</p>
          </div>
          {inc.measurements.length > 0 && (
            <Card>
              <Table>
                <MeasurementHistoryTableHeader />
                <MeasurementHistoryTableBody measurements={inc.measurements} />
              </Table>
            </Card>
          )}
        </Card>
      ))}
    </div>
  )
}

function MeasurementHistoryTableHeader() {
  return (
    <TableHeader className="border-b border-gray-200">
      <TableRow>
        <TableHead>Zählung:</TableHead>
        <TableHead>Gezählt von:</TableHead>
        <TableHead>Zähldatum:</TableHead>
        <TableHead>Uhrzeit:</TableHead>
      </TableRow>
    </TableHeader>
  )
}

function MeasurementHistoryTableBody({
  measurements,
}: {
  measurements: Measurement[]
}) {
  return (
    <TableBody>
      {measurements.map(measure => (
        <TableRow key={measure.id}>
          <TableCell>{measure.measurement}</TableCell>
          <TableCell>
            <Username userId={measure.employeeId} />
          </TableCell>
          <TableCell>{formatDate(measure.measurementDate, de)}</TableCell>
          <TableCell>{formatTime(measure.measurementDate, de)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

export default IncubationMetadataRow
