import { SampleError } from "@/services/backend/samples/sample-error/sample-error"
import { Badge } from "@/shared/components/ui/badge"
import { ReactElement } from "react"

interface ErrorBadgeProps {
  sampleError: SampleError
}

export default function ErrorBadge({
  sampleError,
}: ErrorBadgeProps): ReactElement {
  return (
    <Badge className="bg-red-600 text-white">{sampleError.description}</Badge>
  )
}
