import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import { Input } from "@/shared/components/ui/input"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { useState } from "react"
import { useCreateContactMutation } from "@/services/backend/contacts/contact/service"
import { ScrollArea } from "@/shared/components/ui/scroll-area"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"

const FormSchema = z.object({
  salutation: z.string(),
  title: z.string(),
  name: z.string(),
  function: z.string(),
  email: z.string(),
  phone: z.string(),
  mobilePhone: z.string(),
})

interface CreateContactDialogProps {
  customerId: string
}

export function CreateContactDialog({ customerId }: CreateContactDialogProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [createContact, result] = useCreateContactMutation()
  const { reset, error } = result

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  const handleClose = () => {
    if (isOpen) {
      reset()
      form.reset()
    }
    setIsOpen(!isOpen)
  }

  function onSubmit(data: z.infer<typeof FormSchema>) {
    createContact({
      id: uuidv4(),
      customerId,
      salutation: data.salutation,
      title: data.title,
      name: data.name,
      function: data.function,
      email: data.email,
      phone: data.phone,
      mobilephone: data.mobilePhone,
    })
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogTrigger asChild>
        <Button variant="outline">Neuen Kontakt anlegen</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Neuen Kontakt anlegen</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-6"
            >
              <ScrollArea className="h-[500px] w-full">
                <div className="p-2">
                  <FormField
                    control={form.control}
                    name="salutation"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Anrede</FormLabel>
                        <FormControl>
                          <Input placeholder="Anrede" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Titel</FormLabel>
                        <FormControl>
                          <Input placeholder="Titel" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name</FormLabel>
                        <FormControl>
                          <Input placeholder="Name" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="function"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Funktion</FormLabel>
                        <FormControl>
                          <Input placeholder="Funktion" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>E-Mail</FormLabel>
                        <FormControl>
                          <Input placeholder="E-Mail" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Telefon</FormLabel>
                        <FormControl>
                          <Input placeholder="Telefon" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="mobilePhone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Handy</FormLabel>
                        <FormControl>
                          <Input placeholder="Handy" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </ScrollArea>

              <Button type="submit">Speichern</Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
