import { backendApi } from "@/services/api"
import type {
  Condition,
  State,
} from "@/services/backend/deliveries/state/state"
import {
  DeliveryAnnouncement,
  SampleSetAnnouncement,
  type Delivery,
} from "@/services/backend/deliveries/delivery/delivery"
import { EntityId } from "@/shared/nidavellir/types/entity-id"

const deliveriesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    deliveriesList: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/deliveries/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "Delivery" as const,
                id,
              })),
              { type: "Delivery", id: "LIST" },
            ]
          : [{ type: "Delivery", id: "LIST" }],
    }),
    putDelivery: build.mutation<Delivery, PutDeliveryRequest>({
      query: request => ({
        url: `/inspections/deliveries`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Delivery"],
    }),
    getDelivery: build.query<Delivery, GetRequest>({
      query: request => ({
        url: `/inspections/deliveries`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "Delivery", id: request.id },
      ],
    }),
    announceDelivery: build.mutation<
      DeliveryAnnouncement,
      AnnounceDeliveryRequest
    >({
      query: request => ({
        url: `/inspections/announcements-internal`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["Delivery", "DeliveryTemplate"],
    }),
  }),
})

export const {
  useDeliveriesListQuery,
  useGetDeliveryQuery,
  usePutDeliveryMutation,
  useAnnounceDeliveryMutation,
} = deliveriesApi

export interface UpdateConditionRequest {
  id: string
  condition: string
}

export interface GetRequest {
  id: string
}

export interface DeleteRequest {
  id: string
}

export interface ListRequest {
  limit: number
  offset: number
}

export interface List {
  count: number
  data: Delivery[]
}

export interface PutDeliveryRequest {
  id: string
  customerId?: string
  description?: string
  shippingDate?: string
  deliveryDate?: string
  creationDate?: string
  deliveryState?: State
  packageCondition?: Condition
  isComplete?: boolean
  trackingId?: string
}

export interface AnnounceDeliveryRequest {
  id: string
  customerId: EntityId
  trackingId: string | undefined
  description: string
  sampleSets: SampleSetAnnouncement[]
}
