import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { growthMedia } from "@/shared/components/enum-labels"
import { Card } from "@/shared/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table"
import { formatDate } from "@/shared/utilities/date"
import { de } from "date-fns/locale"
import { ReactNode } from "react"

interface ProcedureMetadataProps {
  procedure: Procedure
}

export default function ProcedureMetadata({
  procedure,
}: ProcedureMetadataProps): ReactNode {
  return (
    <Card>
      <Table>
        <ProcedureMetadataTableHeader />
        <ProcedureMetadataTableBody procedure={procedure} />
      </Table>
    </Card>
  )
}

function ProcedureMetadataTableHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>Nährmedien</TableHead>
        <TableHead>Inkubationszeit</TableHead>
        <TableHead>Zählzeitraum</TableHead>
        <TableHead>Inkubationstemperatur</TableHead>
      </TableRow>
    </TableHeader>
  )
}

function ProcedureMetadataTableBody({ procedure }: { procedure: Procedure }) {
  return (
    <TableBody>
      {procedure.incubation.map(inc => (
        <TableRow key={inc.id}>
          <TableCell>{growthMedia[inc.growthMedium]}</TableCell>
          <TableCell>
            <p>Mindestens: {inc.minIncubationTime} Stunden</p>
            <p>Maximal: {inc.maxIncubationTime} Stunden</p>
          </TableCell>
          <TableCell>
            <p>Start: {formatDate(inc.countStartTimestamp, de)}</p>
            <p>Ende: {formatDate(inc.countEndTimestamp, de)}</p>
          </TableCell>
          <TableCell>
            <p>Mindestens: {inc.minTemperature} Grad Celsisus</p>
            <p>Maximal: {inc.maxTemperature} Grad Celsisus</p>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
