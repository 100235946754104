import { usePostInspectionMutation } from "@/services/backend/inspections/service"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import ConfirmationDialog from "@/shared/components/domain/confirmation/confirmation-dialog"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  useErrArtefactNotReady,
  useSetErrArtefactNotReadyFromRtk,
} from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { Button } from "@/shared/components/ui/button"
import { ReactElement } from "react"
import { v4 } from "uuid"

interface CreateReportButtonProps {
  processId: string
}

export default function CreateReportButton({
  processId,
}: CreateReportButtonProps): ReactElement {
  const [createReport, { error, reset, isLoading }] =
    usePostInspectionMutation()
  const { resetNotReadyError } = useErrArtefactNotReady()
  useSetErrArtefactNotReadyFromRtk(error, () => {
    reset()
  })

  const {
    data: process,
    isLoading: processIsLoading,
    error: processError,
  } = useGetProcessQuery({
    id: processId ?? "",
  })

  if (processIsLoading) {
    return <div />
  }

  if (processError) {
    return <RTKQueryErrorAlert error={error} />
  }

  const onConfirm = () => {
    if (isLoading) {
      return
    }

    if (!process) {
      return
    }

    resetNotReadyError()
    createReport({
      id: v4(),
      processId,
      customerId: process?.customerId,
    })
  }

  return (
    <ConfirmationDialog
      trigger={
        <Button>
          <p>Inspektionsbericht erstellen</p>
        </Button>
      }
      title="Neuen Prüfbericht erstellen"
      description="Hiermit wird ein Bericht mit den aktuellen Daten des Vorgangs angelegt."
      onConfirm={onConfirm}
    />
  )
}
