import { useState, useCallback } from "react"

import { Check, Copy } from "lucide-react"
import { Input } from "@/shared/components/ui/input"
import { Button } from "@/shared/components/ui/button"

interface SecretFieldProps {
  secret: string
}

export default function SecretField({ secret }: SecretFieldProps) {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(secret).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    })
  }, [secret])

  return (
    <div className="flex w-full max-w-sm items-center space-x-2">
      <Input
        type="text"
        value={secret}
        readOnly
        className="font-mono"
        aria-label="Secret value"
      />
      <Button
        onClick={copyToClipboard}
        variant="outline"
        size="icon"
        aria-label="Copy to clipboard"
      >
        {isCopied ? (
          <Check className="h-4 w-4 text-green-500" />
        ) : (
          <Copy className="h-4 w-4" />
        )}
      </Button>
    </div>
  )
}
