import { HTMLAttributes } from "react"
import { cn } from "@/shared/lib/utils"
import {
  TabsNav,
  TabsNavContent,
  TabsNavLink,
  TabsNavList,
} from "@/shared/layout/tabs-nav"

export interface ArtefactPageProps extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactPage({
  children,
  className,
  ...props
}: ArtefactPageProps) {
  return (
    <TabsNav>
      <div className={cn("grid grid-cols-1 gap-2", className)} {...props}>
        {children}
      </div>
    </TabsNav>
  )
}

export interface ArtefactPageHeaderProps
  extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactPageHeader({
  children,
  className,
  ...props
}: ArtefactPageHeaderProps) {
  return (
    <div
      className={cn("flex items-center justify-between", className)}
      {...props}
    >
      {children}
    </div>
  )
}

export interface ArtefactPageActionsProps
  extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactPageActions({
  children,
  className,
  ...props
}: ArtefactPageActionsProps) {
  return (
    <div className={cn("flex items-center space-x-2", className)} {...props}>
      {children}
    </div>
  )
}

export const ArtefactPageNavigation = TabsNavList

export const ArtefactPageNavigationLink = TabsNavLink

export const ArtefactPageNavigationOutlet = TabsNavContent

export interface ArtefactPageContentProps
  extends HTMLAttributes<HTMLHeadingElement> {}

export function ArtefactPageContent({
  children,
  className,
  ...props
}: ArtefactPageContentProps) {
  return (
    <div className={cn("grid grid-cols-1 gap-2", className)} {...props}>
      {children}
    </div>
  )
}
