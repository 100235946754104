import { ProcessStatus } from "@/services/backend/processes/process/process"
import { cn } from "@/shared/lib/utils"
import {
  CirclePlus,
  Clipboard,
  ClipboardCheck,
  Clock,
  FileCheck,
  LucideIcon,
  Search,
  TestTube,
  TestTubeDiagonal,
  TestTubes,
} from "lucide-react"

export const processStateIcon: { [key: string]: LucideIcon } = {
  [ProcessStatus.Created]: CirclePlus,
  [ProcessStatus.Scheduled]: Clock,
  [ProcessStatus.IncubationStarted]: TestTubeDiagonal,
  [ProcessStatus.FirstMeasurementDone]: TestTube,
  [ProcessStatus.SecondMesasurementDone]: TestTubes,
  [ProcessStatus.DifferentiationDone]: Search,
  [ProcessStatus.InspectionReportCreated]: Clipboard,
  [ProcessStatus.InspectionReportReleased]: ClipboardCheck,
  [ProcessStatus.Completed]: FileCheck,
}

// eslint-disable-next-line react/no-unused-prop-types
export function StatusIcon(props: { icon: LucideIcon }) {
  return (
    <span
      className={cn(
        "flex h-8 w-8 items-center justify-center rounded-full bg-secondary",
      )}
    >
      <props.icon className={cn("h-4 w-4")} />
    </span>
  )
}

export const processStateLabel: { [key: string]: string } = {
  [ProcessStatus.Created]: "Erstellt",
  [ProcessStatus.Scheduled]: "Eingeplant",
  [ProcessStatus.IncubationStarted]: "Inkubation gestartet",
  [ProcessStatus.FirstMeasurementDone]: "Zählung 1",
  [ProcessStatus.SecondMesasurementDone]: "Zählung 2",
  [ProcessStatus.DifferentiationDone]: "Differenziert",
  [ProcessStatus.InspectionReportCreated]: "Bericht erstellt",
  [ProcessStatus.InspectionReportReleased]: "Bericht freigegeben",
  [ProcessStatus.Completed]: "Fertig",
}

// eslint-disable-next-line react/no-unused-prop-types
export function StatusLabel({ status }: { status: string }) {
  return <span className={cn("flex")}>{processStateLabel[status]}</span>
}
