import { useGetLatestInspectionByProcessQuery } from "@/services/backend/inspections/service"
import { ProcessStatus } from "@/services/backend/processes/process/process"
import { useGetProcessQuery } from "@/services/backend/processes/process/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import { processStateIcon } from "@/shared/components/domain/processes/get-status-label"
import { H2 } from "@/shared/components/typography"
import {
  Milestone,
  MilestoneIcon,
  Milestones,
  MilestoneStatus,
  MilestoneTitle,
} from "@/shared/service-manager/default-ui/process-page/process-page-overview"
import { Skeleton } from "@/shared/components/ui/skeleton"
import { formatDate } from "@/shared/utilities/date"
import { useParams } from "react-router"

export default function OverviewRoute() {
  const { processId } = useParams()

  return (
    <div className="grid grid-cols-1 gap-4">
      <H2 className="border-none">Übersicht</H2>
      <Milestones>
        <IncubationStartedMilestone processId={processId!} />
        <InspectionCreatedMilestone processId={processId!} />
        <InspectionReleasedMilestone processId={processId!} />
      </Milestones>
    </div>
  )
}

function IncubationStartedMilestone({ processId }: { processId: string }) {
  const { data, error, isLoading } = useGetProcessQuery({
    id: processId,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (!data || isLoading) {
    return <Skeleton />
  }

  return (
    <Milestone>
      <MilestoneIcon
        icon={processStateIcon[ProcessStatus.IncubationStarted]}
        done={
          data.status === ProcessStatus.IncubationStarted ||
          data.status === ProcessStatus.InspectionReportCreated ||
          data.status === ProcessStatus.InspectionReportReleased
        }
      />
      {data.incubationStart !== "0001-01-01T00:53:28+00:53" && (
        <div>
          <MilestoneTitle>Inkubation wurde gestartet</MilestoneTitle>
          <MilestoneStatus>
            {`Die Inkubation wurde am ${formatDate(data.incubationStart ?? "")} gestartet.`}
          </MilestoneStatus>
        </div>
      )}
      {data.incubationStart === "0001-01-01T00:53:28+00:53" && (
        <div>
          <MilestoneTitle>Die Inkubation wartet auf den Start</MilestoneTitle>
          <MilestoneStatus>
            Weitere Infos werden nach dem Start angezeigt
          </MilestoneStatus>
        </div>
      )}
    </Milestone>
  )
}

function InspectionCreatedMilestone({ processId }: { processId: string }) {
  const { data, error, isLoading } = useGetProcessQuery({
    id: processId,
  })

  const {
    data: inspection,
    error: inspectionError,
    isLoading: inspectionIsLoading,
  } = useGetLatestInspectionByProcessQuery({
    processId,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (inspectionError) {
    return null
  }

  if (!data || !inspection || isLoading || inspectionIsLoading) {
    return <Skeleton />
  }

  return (
    <Milestone>
      <MilestoneIcon
        icon={processStateIcon[ProcessStatus.InspectionReportCreated]}
        done={
          data.status === ProcessStatus.InspectionReportCreated ||
          data.status === ProcessStatus.InspectionReportReleased
        }
      />
      {inspection && (
        <div>
          <MilestoneTitle>Inspektion wurde erstellt</MilestoneTitle>
          <MilestoneStatus>
            {`Der letzte Bericht wurde am ${formatDate(inspection.inspection.createdAt ?? "")} erstellt.`}
          </MilestoneStatus>
        </div>
      )}
    </Milestone>
  )
}

function InspectionReleasedMilestone({ processId }: { processId: string }) {
  const { data, error, isLoading } = useGetProcessQuery({
    id: processId,
  })

  const {
    data: inspection,
    error: inspectionError,
    isLoading: inspectionIsLoading,
  } = useGetLatestInspectionByProcessQuery({
    processId,
  })

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (inspectionError) {
    return null
  }

  if (!data || !inspection || isLoading || inspectionIsLoading) {
    return <Skeleton />
  }

  return (
    <Milestone>
      <MilestoneIcon
        icon={processStateIcon[ProcessStatus.InspectionReportReleased]}
        done={data.status === ProcessStatus.InspectionReportReleased}
      />
      <MilestoneTitle>Inspektion wurde freigegeben</MilestoneTitle>
      {inspection && (
        <MilestoneStatus>
          {`Der letzte Bericht wurde am ${formatDate(inspection.inspection.immutabilitySetAt ?? "")} freigegeben.`}
        </MilestoneStatus>
      )}
    </Milestone>
  )
}
