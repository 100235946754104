import type { ColumnDef, Table as ReactTable } from "@tanstack/react-table"
import { useState } from "react"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import type { ListRequest } from "@/services/backend/processes/process/service"
import { useCustomersListQuery } from "@/services/backend/customers/customer/service"
import type { Customer } from "@/services/backend/customers/customer/customer"
import { NavLink } from "react-router-dom"
import { Button } from "@/shared/components/ui/button"
import { Search } from "lucide-react"
import { ResetFilters } from "@/routes/processes/_components/reset-filters"
import { ColumnSelection } from "@/shared/components/ui/column-selection-component"
import { PaginationLimitOffset } from "@/shared/components/ui/pagination-limit-offset"
import { CreateCustomerForm } from "./create-customer-form"

const tableColumns: ColumnDef<Customer>[] = [
  {
    id: "name",
    accessorKey: "name",
    header: "Kunde",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.name}</div>
    },
  },
  {
    id: "street",
    accessorKey: "street",
    header: "Straße",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.street}</div>
    },
  },
  {
    id: "houseNumber",
    accessorKey: "houseNumber",
    header: "Hausnummer",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.houseNumber}</div>
    },
  },
  {
    id: "city",
    accessorKey: "city",
    header: "Stadt",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.city}</div>
    },
  },
  {
    id: "zipCode",
    accessorKey: "zipCode",
    header: "Postleitzahl",
    cell: ({ row }) => {
      const customerData = row.original
      return <div>{customerData.zipCode}</div>
    },
  },
  {
    id: "Optionen",
    accessorKey: "id",
    header: "Optionen",
    cell: ({ row }) => (
      <NavLink to={`${row.original.id}/contact`}>
        <Button variant="outline" size="sm" className="-mb-2.5 -mt-1.5">
          <Search className="h-4 w-4" />
        </Button>
      </NavLink>
    ),
  },
]

export function CustomersListTable() {
  const [request, setRequest] = useState<ListRequest>(defaultRequest())
  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useCustomersListQuery(request, {
    refetchOnMountOrArgChange: true,
  })

  const table = useDataTableController(tableColumns, list?.data || [])

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="flex justify-between space-x-2">
        <div className="flex space-x-2">
          <ResetFilters onClick={() => setRequest(defaultRequest())} />
        </div>
        <div className="flex flex-row space-x-2">
          <ColumnSelection table={table} />
          <CreateCustomerForm />
        </div>
      </div>
      <DataTableContainer>
        <DataTable table={table} columns={tableColumns} />
      </DataTableContainer>
      <div className="flex flex-row justify-end">
        {list && (
          <PaginationLimitOffset
            offset={request.offset!}
            limit={request.limit!}
            totalCount={list.count}
            setLimitOffset={(limit, offset) =>
              setRequest({ ...request, limit, offset })
            }
          />
        )}
      </div>
    </div>
  )
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  )
}

const defaultRequest = (): ListRequest => ({
  limit: 10,
  offset: 0,
})
