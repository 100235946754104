import { NavLink, useLocation } from "react-router-dom"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shared/components/ui/breadcrumb"
import { CustomerName } from "@/shared/components/domain/customer/customer-name"
import { Delivery } from "@/services/backend/deliveries/delivery/delivery"

export const subPageLabel: { [key: string]: string } = {
  overview: "Übersicht",
  contact: "Ansprechpartner",
}

export function BreadcrumbsToDelivery({ delivery }: { delivery: Delivery }) {
  const location = useLocation()

  // extract the path prefix for everything before the process id
  const idx = location.pathname.indexOf(delivery.id)
  const prefix = location.pathname.slice(0, idx)

  // extract the artefact kind path segment, if it exists
  const fullDeliveryPath = location.pathname.slice(idx + delivery.id.length + 1)
  const subPage = fullDeliveryPath.split("/")[0]

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to={`/customers/${delivery.customerId}/contact`}>
              <CustomerName customerId={delivery.customerId} />
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator className="hidden md:block" />
        <BreadcrumbItem className="hidden md:block">
          <BreadcrumbLink asChild>
            <NavLink to={`${prefix}${delivery.id}/overview`}>
              <span className="font-mono">{delivery.description}</span>
            </NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {subPage && (
          <>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>{subPageLabel[subPage]}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  )
}
