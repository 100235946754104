import { ReactNode } from "react"
import { useParams } from "react-router"
import { DeliveryTemplatesListTable } from "./_components/delivery-templates-list-table"
import CreateDeliveryTemplateModal from "./_components/create-delivery-template"

export function DeliveryTemplates(): ReactNode {
  const { customerId } = useParams()

  return (
    <div className="flex flex-col space-y-2">
      <CreateDeliveryTemplateModal />
      <DeliveryTemplatesListTable customerId={customerId as string} />
    </div>
  )
}
