import { Customer } from "@/services/backend/customers/customer/customer"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card"
import { Input } from "@/shared/components/ui/input"
import { InputErrorMessages } from "@/shared/components/input-error-messages"
import { Label } from "@/shared/components/ui/label"
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error"
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce"
import { useState } from "react"

export function CustomerCard({
  customer,
  onCustomerChange,
  title = "Kunde",
  description = "Stammdaten des Kunden",
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  customer: Customer
  onCustomerChange: (customer: Customer) => void
  title?: string
  description?: string
  fieldErrors?: { [key: string]: string[] }
  resetFieldError?: (field: string) => void
  disabled?: boolean
}) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return []
    }

    return fieldErrors ? (fieldErrors[field] ?? []) : []
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            id="name"
            placeholder="Name"
            value={customer.name}
            onChange={e => {
              if (resetFieldError) {
                resetFieldError("name")
              }
              onCustomerChange({ ...customer, name: e.target.value })
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("name")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">Straße</Label>
          <Input
            type="text"
            id="street"
            placeholder="Straße"
            value={customer.street}
            onChange={e => {
              if (resetFieldError) {
                resetFieldError("street")
              }
              onCustomerChange({ ...customer, street: e.target.value })
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("street")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">Hausnummer</Label>
          <Input
            type="text"
            id="houseNumber"
            placeholder="Hausnummer"
            value={customer.houseNumber}
            onChange={e => {
              if (resetFieldError) {
                resetFieldError("houseNumber")
              }
              onCustomerChange({ ...customer, houseNumber: e.target.value })
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("houseNumber")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">Stadt</Label>
          <Input
            type="text"
            id="city"
            placeholder="Stadt"
            value={customer.city}
            onChange={e => {
              if (resetFieldError) {
                resetFieldError("city")
              }
              onCustomerChange({ ...customer, city: e.target.value })
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("city")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">Postleitzahl</Label>
          <Input
            type="text"
            id="zipCode"
            placeholder="Postleitzahl"
            value={customer.zipCode}
            onChange={e => {
              if (resetFieldError) {
                resetFieldError("zipCode")
              }
              onCustomerChange({ ...customer, zipCode: e.target.value })
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("zipCode")} />
        </div>
      </CardContent>
    </Card>
  )
}

type Mutation<Req, Res> = Readonly<
  () => [
    (entity: Req) => Promise<{ data: Res } | { error: unknown }> | void,
    {
      isLoading: boolean
      error: unknown
      isSuccess: boolean
      reset: () => void
    },
  ]
>

export function CustomerCardWithMutation<
  Req extends { id: string; customer: Customer },
  Res,
>({
  id,
  customer,
  mutation,
  title = "Kunde",
  description = "Stammdaten des Kunden",
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  id: string
  customer: Customer
  mutation: Mutation<Req, Res>
  title?: string
  description?: string
  fieldErrors?: { [key: string]: string[] }
  resetFieldError?: (field: string) => void
  disabled?: boolean
}) {
  // @ts-expect-error - I do not understand the error. But it works.
  const [request, setRequest] = useState<Req>({ id, customer })
  // @ts-expect-error - I do know how to define an always fitting type for RTK query mutations.
  const [update, { isLoading, error, isSuccess, reset }] = mutation()
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  )

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] }

  return (
    <CustomerCard
      customer={request.customer}
      // @ts-expect-error - I do not understand the error. But it works
      onCustomerChange={c => setRequest({ id, customer: c })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={disabled}
    />
  )
}
