import { zodResolver } from "@hookform/resolvers/zod"
import { Control, useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { useEffect, useState } from "react"
import { Plus, Trash2 } from "lucide-react"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { Card, CardContent } from "@/shared/components/ui/card"
import { ScrollArea } from "@/shared/components/ui/scroll-area"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/shared/components/ui/accordion"
import { createEntityId } from "@/shared/nidavellir/types/entity-id"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  GrowthMedium,
  SampleType,
} from "@/services/backend/samples/sample-set/sample-set"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import { Input } from "@/shared/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { growthMedia, sampleTypes } from "@/shared/components/enum-labels"
import { CustomerFormSelect } from "@/shared/components/domain/customer/customer-form-select"
import {
  PutDeliveryTemplateRequest,
  useGetDeliveryTemplateQuery,
  usePutDeliveryTemplateMutation,
} from "@/services/backend/delivery-templates/service"
import {
  SampleSetTemplate,
  SampleTemplate,
} from "@/services/backend/delivery-templates/delivery"
import { skipToken } from "@reduxjs/toolkit/query"

// SampleAnnouncement Schema
const SampleTemplateSchema = z.object({
  templateId: z.string(),
  description: z.string().min(1, "Eine Beschreibung wird benötigt"),
  growthMedium: z.nativeEnum(GrowthMedium),
})

// SampleSetAnnouncement Schema
const SampleSetTemplateSchema = z.object({
  templateId: z.string(),
  sampleSetNumber: z.string().min(1, "Eine Probensetnummer wird benötigt"),
  sampleType: z.nativeEnum(SampleType),
  referenceId: z.string().min(1, "Eine Referenz wird benötigt"),
  comment: z.string().optional(),
  samples: z.array(SampleTemplateSchema).refine(
    arr => {
      // Extract 'type' values from the array of objects
      const types = arr.map(item => item.growthMedium)
      // Check if the 'types' array has any duplicates by comparing its size to a Set of types
      return new Set(types).size === types.length
    },
    {
      message: "Each 'type' must be unique in the array",
    },
  ),
})

// DeliveryAnnouncement Schema
const DeliveryTemplateSchema = z.object({
  templateId: z.string(),
  customerId: z.string(),
  description: z.string().min(1, "Eine Beschreibung wird benötigt"),
  templateDescription: z
    .string()
    .min(1, "Die Vorlage benötigt eine Beschreibung"),
  sampleSets: z
    .array(SampleSetTemplateSchema)
    .min(
      1,
      "Mindesten ein Probenset muss in einer Lieferung enthaltenseiMindesten ein Probenset muss in einer Lieferung enthaltensein",
    ),
})

type FormData = z.infer<typeof DeliveryTemplateSchema>

interface CreateDeliveryTemplateModalProps {
  templateId?: string
  buttonLabel?: string
}

export default function CreateDeliveryTemplateModal({
  templateId,
  buttonLabel = templateId ? "Bearbeiten" : "Anlegen",
}: CreateDeliveryTemplateModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { data: templateData } = useGetDeliveryTemplateQuery(
    templateId ? { id: templateId } : skipToken,
  )
  const [putDelivery, { error }] = usePutDeliveryTemplateMutation()
  const form = useForm<FormData>({
    resolver: zodResolver(DeliveryTemplateSchema),
    defaultValues: {
      templateId: createEntityId()?.toString(),
      description: "",
      sampleSets: [],
    },
  })
  const { control, handleSubmit, reset } = form
  const {
    fields: sampleSetFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "sampleSets",
  })

  const onSubmit = (data: FormData) => {
    const request: PutDeliveryTemplateRequest = {
      deliveryTemplate: {
        templateId: data.templateId,
        customerId: data.customerId,
        description: data.description,
        templateDescription: data.templateDescription,
        sampleSets: data.sampleSets.map(
          (set): SampleSetTemplate => ({
            templateId: set.templateId,
            sampleSetNumber: set.sampleSetNumber,
            sampleType: set.sampleType,
            comment: set.comment,
            referenceId: set.referenceId,
            samples: set.samples.map(
              (sample): SampleTemplate => ({
                templateId: sample.templateId,
                description: sample.description,
                growthMedium: sample.growthMedium,
              }),
            ),
          }),
        ),
      },
    }

    putDelivery(request)
    form.reset()
    setIsOpen(false)
  }

  useEffect(() => {
    if (templateData) {
      const parsed = DeliveryTemplateSchema.partial().parse(templateData)
      reset(parsed)
    }
  }, [templateData, reset])

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">{buttonLabel}</Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col sm:max-w-[425px] md:max-w-[700px] lg:max-w-[900px]">
        <DialogHeader>
          <DialogTitle>Vorlage anlegen</DialogTitle>
          <DialogDescription>
            Hier können Vorlagen für Lieferungen angelegt werden, die immer
            wieder vorkommen. Diese Vorlagen können zum Erstellen neuer
            Lieferungen verwendet werden.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-1 flex-col"
          >
            <div className="mb-4 space-y-4">
              <CustomerFormSelect
                disabled={templateId != null && templateId !== ""}
                name="customerId"
                control={form.control}
              />
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="templateDescription"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Beschreibung der Vorlage</FormLabel>
                      <FormControl>
                        <FormControl>
                          <Input
                            placeholder="Beschreibung der Vorlage"
                            {...field}
                          />
                        </FormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Beschreibung</FormLabel>
                      <FormControl>
                        <Input placeholder="Beschreibung" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Card container with ScrollArea */}
            <Card className="flex-1">
              <CardContent className="p-0">
                {/* Scroll Area should be the only scrollable area */}
                <ScrollArea className="h-[400px]">
                  <Accordion type="multiple" className="flex w-full flex-col">
                    {sampleSetFields.map((sampleSet, sampleSetIndex) => (
                      <AccordionItem
                        value={`sampleSet-${sampleSetIndex}`}
                        key={sampleSet.id}
                      >
                        <AccordionTrigger className="px-4">
                          <div className="flex w-full items-center justify-between">
                            <span>Probenset {sampleSetIndex + 1}</span>
                            <Button
                              type="button"
                              variant="ghost"
                              size="icon"
                              onClick={e => {
                                e.stopPropagation()
                                remove(sampleSetIndex)
                              }}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="space-y-4 px-4">
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.sampleSetNumber`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Probensetnummer</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Probensetnummer"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.referenceId`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Referenz</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Referenz"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.sampleType`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Probentyp</FormLabel>
                                    <FormControl>
                                      <Select
                                        onValueChange={field.onChange}
                                        value={field.value}
                                      >
                                        <FormControl>
                                          <SelectTrigger
                                            defaultValue={
                                              field.value ||
                                              SampleType.SurfaceMicrobial
                                            }
                                          >
                                            <SelectValue placeholder="Probentyp auswählen" />
                                          </SelectTrigger>
                                        </FormControl>
                                        <SelectContent
                                          defaultValue={
                                            field.value ||
                                            SampleType.SurfaceMicrobial
                                          }
                                        >
                                          {Object.values(SampleType).map(
                                            value => (
                                              <SelectItem
                                                key={value}
                                                value={value}
                                              >
                                                {sampleTypes[value]}
                                              </SelectItem>
                                            ),
                                          )}
                                        </SelectContent>
                                      </Select>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                              <FormField
                                control={control}
                                name={`sampleSets.${sampleSetIndex}.comment`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel>Kommentar</FormLabel>
                                    <FormControl>
                                      <Input
                                        placeholder="Kommentar"
                                        {...field}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <SampleFields
                              control={control}
                              sampleSetIndex={sampleSetIndex}
                            />
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </ScrollArea>
              </CardContent>
            </Card>

            {/* Buttons */}
            <div className="flex justify-between space-x-4 pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() =>
                  append({
                    templateId: createEntityId()?.toString() as string,
                    sampleSetNumber: "",
                    sampleType: SampleType.SurfaceMicrobial,
                    comment: "",
                    referenceId: "",
                    samples: [],
                  })
                }
              >
                <Plus className="mr-2 h-4 w-4" /> Probenset hinzufügen
              </Button>
              <Button type="submit">Lieferung speichern</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

type SampleFieldsProps = {
  control: Control<FormData>
  sampleSetIndex: number
}

function SampleFields({ control, sampleSetIndex }: SampleFieldsProps) {
  const {
    fields: itemFields,
    append: addItem,
    remove,
  } = useFieldArray({
    control,
    name: `sampleSets.${sampleSetIndex}.samples`,
  })

  return (
    <div className="flex w-full flex-col space-y-2 rounded-lg border p-4">
      <h1 className="text-lg font-bold">Proben</h1>
      {itemFields.map((sample, sampleIndex) => (
        <div key={sample.id} className="grid w-full grid-cols-2 gap-4">
          <FormField
            control={control}
            name={`sampleSets.${sampleSetIndex}.samples.${sampleIndex}.description`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Beschreibung</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name={`sampleSets.${sampleSetIndex}.samples.${sampleIndex}.growthMedium`}
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Nährmedium</FormLabel>
                <div className="flex w-full flex-row items-center space-x-2">
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue placeholder="Nährmedium" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.values(GrowthMedium).map(value => (
                          <SelectItem key={value} value={value}>
                            {growthMedia[value]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={e => {
                      e.stopPropagation()
                      remove(sampleIndex)
                    }}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
      <Button
        variant="outline"
        className="mt-8 w-40"
        type="button"
        onClick={() => {
          addItem({
            templateId: createEntityId()!.toString(),
            description: "",
            growthMedium: GrowthMedium.Caso,
          })
        }}
      >
        <Plus className="h-4 w-4" /> Probe hinzufügen
      </Button>
    </div>
  )
}
