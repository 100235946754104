import { ProcedureType } from "@/services/backend/samples/sample-set/sample-set"
import type {
  ColumnDef,
  VisibilityState,
  Table as ReactTable,
} from "@tanstack/react-table"
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/data-table"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { useListProceduresQuery } from "@/services/backend/procedures/procedure/service"
import {
  Incubation,
  Procedure,
} from "@/services/backend/procedures/procedure/procedure"
import { SampleSetNumber } from "@/shared/components/domain/sample-set/sample-set-number"
import { H4 } from "@/shared/components/typography"
import { sampleTypes } from "@/shared/components/enum-labels"
import { CollapsibleDataTable } from "@/shared/components/collapsible-data-table"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/shared/components/ui/collapsible"
import { ChevronDown, ChevronRight } from "lucide-react"
import { useState } from "react"
import IncubationMetadataRow from "./incubation-metadata-row"
import MeasurementLabel from "./measurement-label"
import CommentLabel from "../../_components/comment-label"
import ProcedureMetadata from "./procedure-metadata"

const tableColumns = (): ColumnDef<Procedure>[] => [
  {
    id: "sampleSet",
    accessorKey: "sampleSetId",
    header: "Probensetnummer",
    cell: ({ row }) => {
      const procedureData = row.original
      return <SampleSetNumber sampleSetId={procedureData.sampleSetId} />
    },
  },
  {
    id: "sampleType",
    accessorKey: "sampleType",
    header: "Probentyp",
    cell: ({ row }) => {
      const procedureData = row.original
      return sampleTypes[procedureData.sampleType]
    },
  },
  {
    id: "measurement-1",
    accessorKey: "incubation",
    header: "1. Auszählung",
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 1,
      )
      return incubation != null ? (
        <MeasurementLabel incubation={incubation} />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "measurement-2",
    accessorKey: "incubation",
    header: "2. Auszählung",
    cell: ({ row }) => {
      const procedureData = row.original
      const incubation = procedureData.incubation.find(
        (value: Incubation) => value.sequenceNumber === 2,
      )
      return incubation != null ? (
        <MeasurementLabel incubation={incubation} />
      ) : (
        "Keine Probe gefunden"
      )
    },
  },
  {
    id: "comment",
    accessorKey: "comment",
    header: "Kommentar",
    cell: ({ row }) => {
      const procedureData = row.original
      return <CommentLabel comment={procedureData.comment} />
    },
  },
]

interface ProcedureTableProps {
  processId: string
  reportId: string
  procedureType: ProcedureType
  label: string
  visibilityState?: VisibilityState
}

function ProcedureTable({
  processId,
  reportId,
  procedureType,
  label,
  visibilityState = {},
}: ProcedureTableProps) {
  const [procedureOpen, setProcedureOpen] = useState(false)
  const {
    data: list,
    error,
    isLoading,
    isFetching,
  } = useListProceduresQuery(
    {
      processId: processId as string,
      procedureType: procedureType as ProcedureType,
      limit: 0,
      page: 0,
      ownerId: reportId,
      ownerType: "inspection.Inspection",
    },
    { refetchOnMountOrArgChange: true },
  )

  const columns = tableColumns()
  const table = useDataTableController(
    columns,
    list?.data || [],
    visibilityState,
  )

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={columns} />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  if (list != null && list.data != null && list.data.length > 0) {
    return (
      <div className="flex w-full flex-col space-y-4">
        <Collapsible open={procedureOpen} onOpenChange={setProcedureOpen}>
          <CollapsibleTrigger className="flex flex-row items-center space-x-1">
            <H4>{label}</H4>
            {procedureOpen ? <ChevronDown /> : <ChevronRight />}
          </CollapsibleTrigger>
          <CollapsibleContent>
            {list != null && list.data.length > 0 && (
              <ProcedureMetadata procedure={list.data[0]} />
            )}
          </CollapsibleContent>
        </Collapsible>

        <DataTableContainer>
          <CollapsibleDataTable
            table={table}
            columns={columns}
            collapsibleTriggerId="sampleSet"
            renderCollapsibleContent={(procedure: Procedure) => (
              <IncubationMetadataRow procedure={procedure} />
            )}
          />
        </DataTableContainer>
      </div>
    )
  }

  return <div />
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>
  columns: ColumnDef<TData>[]
}) {
  const { table, columns } = props
  return (
    <DataTableContainer className="animate-pulse">
      <DataTable table={table} columns={columns} />
    </DataTableContainer>
  )
}

export default ProcedureTable
