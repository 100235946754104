import { ReactElement } from "react"
import { useGetSampleSetQuery } from "@/services/backend/samples/sample-set/service"
import { RTKQueryErrorAlert } from "@/shared/components/alerts"
import { Procedure } from "@/services/backend/procedures/procedure/procedure"
import { Progress } from "@/shared/components/ui/progress"
import { useListDifferentiationsQuery } from "@/services/backend/differentiation/service"
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { hasFieldError } from "@/shared/app-lib/errors/validation-error"
import { cn } from "@/shared/lib/utils"
import { getDifferentiatedCount, getMouldCount } from "./mould-count"

interface MouldResultProps {
  sampleSetId: string
  procedure: Procedure
}

function MouldResult({
  sampleSetId,
  procedure,
}: MouldResultProps): ReactElement {
  const { data, error } = useGetSampleSetQuery({ id: sampleSetId })
  const { data: mouldDifferentiation, error: mouldError } =
    useListDifferentiationsQuery({ sampleSetId, limit: 0, offset: 0 })
  const mouldCount = getMouldCount(procedure)
  const { notReadyError } = useErrArtefactNotReady()
  const hasDifferentiationErr = hasFieldError(
    notReadyError,
    `differentiations.${procedure.id}`,
  )

  if (error || mouldError) {
    return <RTKQueryErrorAlert error={error || mouldError} />
  }

  if (mouldCount === 0) {
    return <div>Es liegen keine Pilze vor</div>
  }

  if (
    data != null &&
    mouldDifferentiation != null &&
    mouldDifferentiation.items != null
  ) {
    const differentiatedCount = getDifferentiatedCount(
      mouldDifferentiation?.items,
    )
    return (
      <div
        className={cn(
          "flex w-1/2 flex-col",
          hasDifferentiationErr ? "rounded-lg border border-red-500 p-2" : "",
        )}
      >
        {`${differentiatedCount} / ${mouldCount}`}
        <Progress
          className="h-2"
          value={(differentiatedCount / mouldCount) * 100}
        />
      </div>
    )
  }

  return <div>Keine Daten gefunden</div>
}

export default MouldResult
