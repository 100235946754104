import { ReactElement } from "react"
import { BatchComponent } from "@/shared/components/domain/samples/batch-component"
import { Card } from "@/shared/components/ui/card"
import { Sample } from "@/services/backend/samples/sample-set/sample-set"
import ErrorBadge from "./error-badge"
import CommentLabel from "../../_components/comment-label"

interface SampleMetadataRowProps {
  sample: Sample
}

function SampleMetadataRow({ sample }: SampleMetadataRowProps): ReactElement {
  return (
    <Card className="flex flex-row items-center justify-between p-4">
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Charge:</p>
        {sample.batchId !== "" && sample.batchId != null ? (
          <BatchComponent batchId={sample.batchId} />
        ) : (
          "Keine Charge gefunden"
        )}
      </div>
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Fehler:</p>
        <div className="flex flex-row space-x-1">
          {sample.sampleErrors != null && sample.sampleErrors.length > 0 ? (
            sample.sampleErrors.map(sampleError => (
              <ErrorBadge sampleError={sampleError} />
            ))
          ) : (
            <p>Keine Fehler vorhanden</p>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start space-y-2">
        <p className="font-bold">Kommentar:</p>
        <CommentLabel comment={sample.comment} />
      </div>
    </Card>
  )
}

export default SampleMetadataRow
