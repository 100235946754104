import { acquireMSGraphAccessToken } from "@/shared/lib/authorization/msal-access-token"

/**
 * provideBackendToken will provide a token for the backend depending on the
 * current env.
 */
export async function provideMSGraphToken(): Promise<string> {
  switch (import.meta.env.VITE_AUTH) {
    case "dev":
      // A call to the ms graph api required the user to be authenticated
      // via msal. If "dev" is used, there is no token for the ms graph api.
      // (note: technically it would be possible to return
      // acquireMSGraphAccessToken() here. This would then fail and ask
      // the user / developer to sign in with MSAL. However, this is likely,
      // annoying. The dev user is here to skip this step in the first place.)
      return "Some invalid token."
    default:
      return acquireMSGraphAccessToken()
  }
}
