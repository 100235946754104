export function useTranslation() {
  // TODO call to backend for translations
  // * cached with rtk query
  // * cache language choice somewhere
  return {
    t,
  }
}

export default function t(string?: string) {
  if (!string) {
    return ""
  }
  return translations[string] ?? string
}

const translations: Record<string, string> = {
  actions: "Aktionen",
  caso: "Caso",
  Caso: "Caso",
  email: "E-Mail",
  malt: "Malz",
  Malt: "Malz",
  report: "Bericht",
  reports: "Berichte",
}
