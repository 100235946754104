import { backendApi } from "@/services/api"
import { type Delivery } from "@/services/backend/deliveries/delivery/delivery"
import { DeliveryTemplate } from "./delivery"

const deliveryTemplatesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    listDeliveryTemplates: build.query<List, ListRequest>({
      query: request => ({
        url: `/inspections/delivery-templates/list`,
        method: "GET",
        params: {
          customerId: request.customerId,
          limit: request.limit,
          offset: request.offset,
        },
      }),
      providesTags: ["DeliveryTemplate"],
    }),
    putDeliveryTemplate: build.mutation<Delivery, PutDeliveryTemplateRequest>({
      query: request => ({
        url: `/inspections/delivery-templates`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["DeliveryTemplate"],
    }),
    getDeliveryTemplate: build.query<DeliveryTemplate, GetRequest>({
      query: request => ({
        url: `/inspections/delivery-templates`,
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "DeliveryTemplate", id: request.id },
      ],
    }),
  }),
})

export const {
  useGetDeliveryTemplateQuery,
  useListDeliveryTemplatesQuery,
  usePutDeliveryTemplateMutation,
} = deliveryTemplatesApi

export interface GetRequest {
  id: string
}

export interface ListRequest {
  customerId: string
  limit: number
  offset: number
}

export interface List {
  count: number
  items: DeliveryTemplate[]
}

export interface PutDeliveryTemplateRequest {
  deliveryTemplate: DeliveryTemplate
}
