import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"
import { backendApi } from "@/services/api"
import { msGraphApi } from "./services/ms-graph/ms-graph-api"

export const store = configureStore({
  reducer: {
    [backendApi.reducerPath]: backendApi.reducer,
    [msGraphApi.reducerPath]: msGraphApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(backendApi.middleware)
      .concat(msGraphApi.middleware),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
