import { DeliveryTemplate } from "@/services/backend/delivery-templates/delivery"
import { useListDeliveryTemplatesQuery } from "@/services/backend/delivery-templates/service"
import { ReactNode } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { skipToken } from "@reduxjs/toolkit/query"
import { RTKQueryErrorAlert } from "../errors/rtk-query-error-alert"

interface DeliveryTemplateSelectorProps {
  customerId: string
  setDeliveryTemplate: (template: DeliveryTemplate) => void
}

export default function DeliveryTemplateSelector({
  customerId,
  setDeliveryTemplate,
}: DeliveryTemplateSelectorProps): ReactNode {
  const { data: templateData, error } = useListDeliveryTemplatesQuery(
    customerId && customerId !== ""
      ? { customerId, limit: 0, offset: 0 }
      : skipToken,
  )

  const handleChange = (value: string) => {
    const currentTemplate = templateData?.items.find(
      template => (template.templateId as string) === value,
    )
    if (currentTemplate) {
      setDeliveryTemplate(currentTemplate)
    }
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <Select onValueChange={value => handleChange(value)}>
      <SelectTrigger>
        <SelectValue placeholder="Vorlage" />
      </SelectTrigger>
      <SelectContent>
        {templateData && templateData.items && templateData.items.length > 0 ? (
          templateData?.items.map(template => (
            <SelectItem value={template.templateId} key={template.templateId}>
              {template.templateDescription}
            </SelectItem>
          ))
        ) : (
          <div className="p-1 accent-muted-foreground">
            Keine Templates für diesen Kunden vorhanden
          </div>
        )}
      </SelectContent>
    </Select>
  )
}
