import { HTMLAttributes } from "react"
import { useShowUserQuery } from "@/services/ms-graph/ms-graph-api"
import { Skeleton } from "@/shared/components/ui/skeleton"
import { cn } from "@/shared/lib/utils"

interface Props extends HTMLAttributes<HTMLSpanElement> {
  userId: string
}

export function Username({ userId, className, ...props }: Props) {
  const { data, isLoading, error } = useShowUserQuery({ id: userId })

  if (error) {
    return <span {...props}>N/A</span>
  }

  if (isLoading) {
    return <Skeleton {...props} className={cn("h-4 w-28", className)} />
  }

  return <span {...props}>{data?.displayName}</span>
}
