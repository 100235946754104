import { ArtefactImmutabilityReason } from "@/services/backend/inspections/inspection"
import {
  useInspectionPdfPreviewQuery,
  useSetInspectionImmutableMutation,
} from "@/services/backend/inspections/service"
import { useAuth } from "@/shared/lib/authorization/auth-context"
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsDownload,
  ArtefactSheetActionsHeader,
  ArtefactSheetActionsLock,
  ArtefactSheetContent,
  ArtefactSheetHeader,
  ArtefactSheetImmutabilityWarning,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
} from "@/shared/service-manager/artefact/artefact-sheet"
import { Button } from "@/shared/components/ui/button"
import { Separator } from "@radix-ui/react-dropdown-menu"

export function InspectionReportPdf({ reportId }: { reportId: string }) {
  const { user } = useAuth()
  const { data, isLoading, error } = useInspectionPdfPreviewQuery({
    inspectionId: reportId,
  })

  if (!user) {
    return null
  }

  return (
    <ArtefactSheet>
      <ArtefactSheetTrigger asChild>
        <Button variant="outline">PDF</Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="max-h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>Download PDF</ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <ArtefactSheetPreview
            objectURL={data?.objectURL}
            isLoading={isLoading}
            error={error}
          />
          <ArtefactSheetActions>
            <ArtefactSheetActionsHeader
              title="Prüfbericht"
              artefactNumber={null}
            />
            <ArtefactSheetActionsContent>
              <ArtefactSheetImmutabilityWarning
                title="Hinweis"
                description="Wenn ein Bericht heruntergeladen wird, wird er festgeschrieben. Er kann dann nicht mehr geändert werden."
              />
              <Separator />
              <ArtefactSheetActionsDownload>
                <Button variant="outline">Bericht herunterladen</Button>
              </ArtefactSheetActionsDownload>
              <Separator />
              <ArtefactSheetActionsLock
                mutation={useSetInspectionImmutableMutation}
                request={{
                  id: reportId,
                  reason: ArtefactImmutabilityReason.InspectionReleased,
                  setBy: user.id as string,
                }}
              />
            </ArtefactSheetActionsContent>
          </ArtefactSheetActions>
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  )
}
