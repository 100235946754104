import { useCustomersListQuery } from "@/services/backend/customers/customer/service"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/shared/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select"
import { Control, FieldValues, Path } from "react-hook-form"
import { RTKQueryErrorAlert } from "../errors/rtk-query-error-alert"

interface CustomerFormSelectProps<T extends FieldValues> {
  name: Path<T>
  control: Control<T>
  disabled?: boolean
}

export function CustomerFormSelect<T extends FieldValues>({
  control,
  name,
  disabled = false,
}: CustomerFormSelectProps<T>) {
  const { data: customerList, error } = useCustomersListQuery({
    limit: 0,
    offset: 0,
  })
  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>Kunde</FormLabel>
          <Select
            disabled={disabled}
            onValueChange={field.onChange}
            defaultValue={field.value}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Einen Kunden auswählen" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {customerList != null &&
                customerList.data != null &&
                customerList.data.map(customer => (
                  <SelectItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  )
}
