import { useParams } from "react-router"
import {
  useGetInspectionQuery,
  useInspectionUpdateCustomerMutation,
} from "@/services/backend/inspections/service"
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert"
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready"
import { CustomerCardWithMutation } from "@/shared/components/domain/customer/customer-card"

export function CustomerIndexRoute() {
  const { reportId } = useParams()
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady()
  const { data, isLoading, error } = useGetInspectionQuery({
    id: reportId as string,
  })

  if (isLoading || !data) {
    return <div />
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />
  }

  return (
    <CustomerCardWithMutation
      id={reportId as string}
      customer={data.inspection.customer}
      mutation={useInspectionUpdateCustomerMutation}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "customer.")}
      resetFieldError={field => resetNotReadyErrorField(`customer.${field}`)}
    />
  )
}
