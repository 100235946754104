import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { v4 as uuidv4 } from "uuid"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import { Input } from "@/shared/components/ui/input"
import { Button } from "@/shared/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { useCreateCustomerMutation } from "@/services/backend/customers/customer/service"

const FormSchema = z.object({
  name: z.string(),
  street: z.string(),
  houseNumber: z.string(),
  city: z.string(),
  zipCode: z.string()
})

export function CreateCustomerForm() {
  const [createCustomer] = useCreateCustomerMutation()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    createCustomer({
      id: uuidv4(),
      name: data.name,
      street: data.street,
      houseNumber: data.houseNumber,
      city: data.city,
      zipCode: data.zipCode
    })
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Kunden anlegen</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Kunden anlegen</DialogTitle>
          <DialogDescription>
            Hier können Sie neue Kunden anlegen
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-2"
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name des Kunden</FormLabel>
                    <FormControl>
                      <Input placeholder="Kunde" {...field} />
                    </FormControl>
                    <FormDescription>Name des Kunden</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="street"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Straße</FormLabel>
                    <FormControl>
                      <Input placeholder="Straße" {...field} />
                    </FormControl>
                    <FormDescription>Straße</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="houseNumber"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Hausnummer</FormLabel>
                    <FormControl>
                      <Input placeholder="Hausnummer" {...field} />
                    </FormControl>
                    <FormDescription>Hausenummer</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Stadt</FormLabel>
                    <FormControl>
                      <Input placeholder="Stadt" {...field} />
                    </FormControl>
                    <FormDescription>Stadt</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="zipCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Postleitzahl</FormLabel>
                    <FormControl>
                      <Input placeholder="Postleitzahl" {...field} />
                    </FormControl>
                    <FormDescription>Postleitzahl</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Speichern</Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}
