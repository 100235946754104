import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shared/components/ui/form"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog"
import { Button } from "@/shared/components/ui/button"
import { v4 as uuidv4 } from "uuid"
import { Input } from "@/shared/components/ui/input"
import { usePutAirSamplerMutation } from "@/services/backend/airsamplers/service"

const formSchema = z.object({
  description: z.string(),
  volume: z.string(),
  holeCount: z.string(),
})

export default function PutAirSamplerDialog() {
  const [putSampler] = usePutAirSamplerMutation()
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
      volume: "",
      holeCount: "",
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    putSampler({
      id: uuidv4(),
      description: values.description,
      volume: Number(values.volume),
      holeCount: Number(values.holeCount),
    })
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <p>Luftkeimsammler anlegen</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Neuen Luftkeimsammler anlegen</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full space-y-4"
            >
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Beschreibung</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Beschreibung"
                        type="text"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Beschreibung des Luftkeimsammlers (z.B. Name)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="volume"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Volumen</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Volumen"
                        type="number"
                        min={0}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Volumen des Luftkeimsammlers
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="holeCount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Lochzahl</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Lochzahl"
                        type="number"
                        min={0}
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Lochanzahl des Luftkeimsammlers
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit">Submit</Button>
            </form>
          </Form>{" "}
        </div>
      </DialogContent>
    </Dialog>
  )
}
